import { Component, inject } from '@angular/core';
import { DialogComponent } from '@konnektu/components';
import { QueryTypeIds, SegmentTypeIds } from '@konnektu/domain-models';
import { DestroyService, createContextLogger } from '@konnektu/helpers';
import {
  DataType,
  MetastoreSelectRequest,
  MetastoreService,
  extractObjectParams,
  getTableMetaByPath
} from '@konnektu/metastore';
import {
  BehaviorSubject,
  filter,
  forkJoin,
  map,
  shareReplay,
  takeUntil
} from 'rxjs';

export type CopySegmentResult = CopySegmentRequest | 'cancel';

export interface CopySegmentRequest {
  name: string;
  type?: SegmentTypeIds;
  query: {
    source: QueryTypeIds;
    text: string | MetastoreSelectRequest;
  };
  index?: { columns: { name: string; type: DataType }[]; unique: boolean };
}

@Component({
  selector: 'knk-copy-segment',
  templateUrl: 'segment-copy-dialog.component.html',
  styleUrls: ['segment-copy-dialog.component.scss'],
  providers: [DestroyService]
})
export class SegmentCopyDialogComponent extends DialogComponent<CopySegmentResult | null> {
  private readonly logger = createContextLogger('SegmentCopyDialogComponent');

  private readonly metastore = inject(MetastoreService);
  private readonly destroy$ = inject(DestroyService);

  name?: string;

  from$ = new BehaviorSubject<string | null>(null);

  fields: string[] = [];

  filterExpression: Record<string, any> | null = null;

  currentVersion: number | null = null;

  isIndexUnique = false;

  indexedColumns: string[] = [];

  metadataProvider$ = this.metastore
    .metadata({ ui: { section: 'CopySegmentDialogComponent' } })
    .pipe(shareReplay(1));

  fromTableOptions$ = this.metadataProvider$.pipe(
    map((tables) => tables.map((dto) => ({ label: dto.name, value: dto.name })))
  );

  changeSelectedProperties(newProps: string[]) {
    this.fields = newProps;
  }

  cancel() {
    this.closeSelf(null);
  }

  done() {
    const fromValue = this.from$.getValue();

    if (!fromValue || !this.filterExpression) {
      return;
    }

    const { obj, parameters } = extractObjectParams(this.filterExpression);

    if (this.currentVersion) {
      forkJoin(
        this.fields
          .filter((f) => this.indexedColumns.includes(f))
          .map((f) =>
            getTableMetaByPath(
              this.metadataProvider$,
              fromValue,
              f.split('.')
            ).pipe(
              filter((meta) => typeof meta === 'number'),
              map((meta) => ({ meta, field: f }))
            )
          )
      )
        .pipe(takeUntil(this.destroy$))
        .subscribe((tableOrFieldMeta) => {
          const indexColumns = tableOrFieldMeta.map(({ meta, field }) => ({
            name: field,
            type: meta as DataType
          }));
          this.closeSelf({
            name: this.name ?? 'Unnamed',
            query: {
              text: {
                query: {
                  $from: fromValue,
                  $select: this.fields,
                  $where: obj
                },
                parameters
              },
              source: QueryTypeIds.Postgres
            },
            index: {
              columns: indexColumns,
              unique: this.isIndexUnique
            }
          });
        });
    } else {
      forkJoin(
        this.fields
          .filter((f) => this.indexedColumns.includes(f))
          .map((f) =>
            getTableMetaByPath(
              this.metadataProvider$,
              fromValue,
              f.split('.')
            ).pipe(
              filter((meta) => typeof meta === 'number'),
              map((meta) => ({ meta, field: f }))
            )
          )
      )
        .pipe(takeUntil(this.destroy$))
        .subscribe((tableOrFieldMeta) => {
          const indexColumns = tableOrFieldMeta.map(({ meta, field }) => ({
            name: field,
            type: meta as DataType
          }));
          this.closeSelf({
            index: { columns: indexColumns, unique: this.isIndexUnique },
            name: this.name ?? 'Unnamed',
            query: {
              text: {
                query: {
                  $from: fromValue,
                  $select: this.fields,
                  $where: obj
                },
                parameters
              },
              source: QueryTypeIds.Postgres
            }
          });
        });
    }
  }
}
