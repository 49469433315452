import { CommunicationChannelIds } from '@konnektu/domain-models';
import { LogicalOperator, MetastoreOperator } from '@konnektu/metastore';
import {
  ConditionBlockDef,
  SimpleFilterTemplate
} from './pages/segment-editor/simple-filter/models';

export const DEFAULT_CONDITION_BLOCKS: ConditionBlockDef[] = [
  {
    id: 'userDataBlock',
    label: 'segmentEditor.simpleFilter.baseTemplate.userDataBlock.label',
    conditions: [
      {
        id: 'userGender',
        label:
          'segmentEditor.simpleFilter.baseTemplate.userDataBlock.conditions.userGender',
        type: 'lookup',
        entityName: 'Gender',
        column: 'User.GenderId'
      },
      {
        id: 'userBirthDate',
        label:
          'segmentEditor.simpleFilter.baseTemplate.userDataBlock.conditions.birthDate',
        type: 'birth-date',
        column: 'User.BirthDate'
      },
      {
        id: 'userSurname',
        label:
          'segmentEditor.simpleFilter.baseTemplate.userDataBlock.conditions.userSurname',
        type: 'string',
        column: 'User.Surname'
      },
      {
        id: 'userGivenName',
        label:
          'segmentEditor.simpleFilter.baseTemplate.userDataBlock.conditions.userGivenName',
        type: 'string',
        column: 'User.GivenName'
      },
      {
        id: 'userMiddleName',
        label:
          'segmentEditor.simpleFilter.baseTemplate.userDataBlock.conditions.userMiddleName',
        type: 'string',
        column: 'User.MiddleName'
      },
      {
        id: 'userCreationDate',
        label:
          'segmentEditor.simpleFilter.baseTemplate.userDataBlock.conditions.creationDate',
        type: 'date',
        column: 'User.CreatedOn'
      },
      {
        id: 'removeTestUsers',
        label:
          'segmentEditor.simpleFilter.baseTemplate.userDataBlock.conditions.removeTestUsers',
        type: 'checkbox',
        createExpression: (state) => ({
          'User.IsTest': { [MetastoreOperator.eq]: !state }
        }),
        state: true
      }
    ]
  },
  {
    label:
      'segmentEditor.simpleFilter.baseTemplate.consentsAndUnsubBlock.label',
    id: 'consentsAndUnsubBlock',
    conditions: [
      {
        id: 'communicationAgree',
        label:
          'segmentEditor.simpleFilter.baseTemplate.consentsAndUnsubBlock.conditions.communicationAgree',
        type: 'checkbox',
        column: 'User.CommunicationAgree',
        state: true
      },
      {
        id: 'personalDataAgree',
        label:
          'segmentEditor.simpleFilter.baseTemplate.consentsAndUnsubBlock.conditions.personalDataAgree',
        type: 'checkbox',
        column: 'User.PersonalDataAgree'
      }
    ]
  },
  {
    label: 'segmentEditor.simpleFilter.baseTemplate.interactionBlock.label',
    id: 'interactionBlock',
    conditions: [
      {
        id: 'interactionDate',
        label:
          'segmentEditor.simpleFilter.baseTemplate.interactionBlock.conditions.interactionDate',
        type: 'date',
        createExpression: (state, defaultCreateExpression) => ({
          'User.UserEvents': {
            [MetastoreOperator.exist]: {
              $where: defaultCreateExpression('CreatedOn', state)
            }
          }
        })
      },
      {
        id: 'source',
        label:
          'segmentEditor.simpleFilter.baseTemplate.interactionBlock.conditions.source',
        type: 'lookup',
        entityName: 'CampaignSource',
        createExpression: (state, defaultCreateExpression) => ({
          'User.UserEvents': {
            [MetastoreOperator.exist]: {
              $where: defaultCreateExpression('SourceId', state)
            }
          }
        })
      }
    ]
  },
  {
    label: 'segmentEditor.simpleFilter.baseTemplate.loyaltyBlock.label',
    id: 'loyaltyBlock',
    conditions: [
      {
        id: 'wallet',
        label:
          'segmentEditor.simpleFilter.baseTemplate.loyaltyBlock.conditions.wallet',
        type: 'lookup',
        entityName: 'BonusWallet',
        createExpression: (state, defaultCreateExpression) => ({
          'User.UserWallets': {
            [MetastoreOperator.exist]: {
              $where: defaultCreateExpression('WalletId', state)
            }
          }
        })
      },
      {
        id: 'walletBalance',
        label:
          'segmentEditor.simpleFilter.baseTemplate.loyaltyBlock.conditions.walletBalance',
        type: 'number',
        createExpression: (state, defaultCreateExpression) => ({
          'User.UserWallets': {
            [MetastoreOperator.exist]: {
              $where: defaultCreateExpression('Wallet.Balance', state)
            }
          }
        })
      }
    ]
  },
  {
    label: 'segmentEditor.simpleFilter.baseTemplate.contactsBlock.label',
    id: 'contactsBlock',
    conditions: [
      {
        id: 'identifierConfirmed',
        label:
          'segmentEditor.simpleFilter.baseTemplate.contactsBlock.conditions.identifierConfirmed',
        type: 'nullable-boolean',
        column: 'Confirmed'
      }
    ]
  }
];

const DEFAULT_COLUMNS = [
  { column: 'Id', index: true },
  { column: 'Value', index: true },
  { column: 'User.Id', index: false },
  { column: 'User.Surname', index: false },
  { column: 'User.GivenName', index: false },
  { column: 'User.MiddleName', index: false }
];

export const DEFAULT_TEMPLATES: SimpleFilterTemplate[] = [
  {
    id: 'emailCommunicationTemplate',
    label: 'segmentEditor.simpleFilter.emailCommunicationTemplateLabel',
    entityName: 'UserIdentifier',
    columns: DEFAULT_COLUMNS,
    isIndexUnique: true,
    additionalFilter: {
      [LogicalOperator.and]: [
        {
          'Type.CommunicationChannel.Id': {
            [MetastoreOperator.eq]: CommunicationChannelIds.Email
          }
        },
        {
          IsActive: { [MetastoreOperator.eq]: true }
        }
      ]
    },
    conditionBlocks: DEFAULT_CONDITION_BLOCKS
  },
  {
    id: 'smsCommunicationTemplate',
    label: 'segmentEditor.simpleFilter.smsCommunicationTemplateLabel',
    entityName: 'UserIdentifier',
    columns: DEFAULT_COLUMNS,
    isIndexUnique: true,
    additionalFilter: {
      [LogicalOperator.and]: [
        {
          'Type.CommunicationChannel.Id': {
            [MetastoreOperator.eq]: CommunicationChannelIds.Sms
          }
        },
        {
          IsActive: { [MetastoreOperator.eq]: true }
        }
      ]
    },
    conditionBlocks: DEFAULT_CONDITION_BLOCKS
  },
  {
    id: 'pushCommunicationTemplate',
    label: 'segmentEditor.simpleFilter.pushCommunicationTemplateLabel',
    entityName: 'UserIdentifier',
    columns: DEFAULT_COLUMNS,
    isIndexUnique: true,
    additionalFilter: {
      [LogicalOperator.and]: [
        {
          'Type.CommunicationChannel.Id': {
            [MetastoreOperator.eq]: CommunicationChannelIds.Push
          }
        },
        {
          IsActive: { [MetastoreOperator.eq]: true }
        }
      ]
    },
    conditionBlocks: DEFAULT_CONDITION_BLOCKS
  }
];
