import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  inject,
  signal
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import {
  LogicalOperator,
  MetastoreOperator,
  MetastoreService,
  equal,
  from,
  or
} from '@konnektu/metastore';
import { TranslateModule } from '@ngx-translate/core';
import {
  TUI_DEFAULT_MATCHER,
  TuiFilterPipeModule,
  TuiLetModule,
  TuiMatcher,
  tuiPure
} from '@taiga-ui/cdk';
import {
  TuiDataListModule,
  TuiDropdownModule,
  TuiHostedDropdownModule,
  TuiLinkModule,
  TuiTextfieldControllerModule
} from '@taiga-ui/core';
import { TuiInputModule, TuiMultiSelectModule } from '@taiga-ui/kit';
import { sortBy } from 'lodash-es';
import { catchError, first, map, of, shareReplay } from 'rxjs';
import { LookupConditionDef } from '../../models';
import { SimpleFilterControl } from '../simple-filter-control';

@Component({
  selector: 'knk-simple-filter-lookup-control',
  templateUrl: 'lookup-control.component.html',
  styleUrl: 'lookup-control.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    TuiMultiSelectModule,
    TuiLetModule,
    TuiDropdownModule,
    TuiTextfieldControllerModule,
    TuiDataListModule,
    TuiLetModule,
    TuiInputModule,
    TuiHostedDropdownModule,
    TuiFilterPipeModule,
    TuiLinkModule
  ]
})
export class LookupControlComponent
  extends SimpleFilterControl<LookupConditionDef>
  implements OnInit
{
  private readonly metastore = inject(MetastoreService);

  private readonly destroyRef = inject(DestroyRef);

  search = '';

  ngOnInit(): void {
    this.updateStateAndExpression(this.state || []);
  }

  readonly filterMatcher: TuiMatcher<{ Id: number; Name: string }> = (
    item,
    search: string
  ) => TUI_DEFAULT_MATCHER(item.Name, search);

  protected readonly selectedItems = signal<{ Id: number; Name: string }[]>([]);

  @tuiPure
  getAllItems() {
    const request = from(this.definition.entityName).select(['Id', 'Name']);
    if (this.definition.additionalFilter) {
      request.where(this.definition.additionalFilter);
    }
    return this.metastore
      .select<{ Id: number; Name: string }>(request.done())
      .pipe(
        shareReplay(1),
        map((values) => values.filter((v) => v.Name)),
        map((items) => sortBy(items, (i) => !this.state?.includes(i.Id)))
      );
  }

  parseState(state: null | number[]) {
    this.getSelectedItems(state ?? [])
      .pipe(
        first(),
        takeUntilDestroyed(this.destroyRef),
        catchError(() => of([]))
      )
      .subscribe((items) => {
        this.selectedItems.set(items);
      });
    return state;
  }

  protected updateState(state: number[]) {
    if (!state.length) {
      this.updateStateAndExpression(null);
    } else {
      this.updateStateAndExpression(state);
    }
  }

  createExpression(column: string, state: number[] | null): object {
    if (!state) {
      return {};
    }
    if (state.length > 1) {
      return {
        [LogicalOperator.or]: state.map((v) => ({
          [column]: { [MetastoreOperator.eq]: v }
        }))
      };
    }
    return {
      [column]: { [MetastoreOperator.eq]: state[0] }
    };
  }

  private getSelectedItems(ids: number[]) {
    if (ids?.length) {
      return this.metastore.select<{
        Id: number;
        Name: string;
      }>(
        from(this.definition.entityName)
          .select(['Id', 'Name'])
          .where(
            ids.length === 1
              ? equal('Id', ids[0])
              : or(...ids.map((id) => equal('Id', id)))
          )
          .done()
      );
    }
    return of([]);
  }
}
