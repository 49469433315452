<tui-select
  [ngModel]="state?.hasActivity"
  [disabled]="disabled"
  (ngModelChange)="updateHasActivity($event)"
  [valueContent]="hasHasNotValueContent"
>
  {{ 'segmentEditor.simpleFilter.controls.commResponseHasNotLabel' | translate }}
  <tui-data-list *tuiDataList>
    <button tuiOption [value]="true">
      {{ 'segmentEditor.simpleFilter.controls.commResponseHas' | translate }}
    </button>
    <button tuiOption [value]="false">
      {{ 'segmentEditor.simpleFilter.controls.commResponseHasNot' | translate }}
    </button>
  </tui-data-list>
</tui-select>

<ng-template #hasHasNotValueContent let-selected>
  <ng-container *ngIf="selected === true">
    {{ 'segmentEditor.simpleFilter.controls.commResponseHas' | translate }}
  </ng-container>
  <ng-container *ngIf="selected === false">
    {{ 'segmentEditor.simpleFilter.controls.commResponseHasNot' | translate }}
  </ng-container>
</ng-template>

<div class="response-selector">
  <tui-select
    [ngModel]="state?.responseTypeId"
    [disabled]="disabled"
    [valueContent]="responseTypeValueContent"
    (ngModelChange)="updateActivityType($event)"
  >
    {{ 'segmentEditor.simpleFilter.controls.commResponseTypeLabel' | translate }}
    <tui-data-list *tuiDataList>
      <button tuiOption [value]="CommunicationActivityTypeId.Sent">
        {{ 'segmentEditor.simpleFilter.controls.commResponseSent' | translate }}
      </button>
      <button tuiOption [value]="CommunicationActivityTypeId.Delivered">
        {{ 'segmentEditor.simpleFilter.controls.commResponseDelivered' | translate }}
      </button>
      <button tuiOption [value]="CommunicationActivityTypeId.Opened">
        {{ 'segmentEditor.simpleFilter.controls.commResponseOpened' | translate }}
      </button>
      <button tuiOption [value]="CommunicationActivityTypeId.Click">
        {{ 'segmentEditor.simpleFilter.controls.commResponseLinkClick' | translate }}
      </button>
      <button tuiOption [value]="CommunicationActivityTypeId.Spam">
        {{ 'segmentEditor.simpleFilter.controls.commResponseSpam' | translate }}
      </button>
      <button tuiOption [value]="CommunicationActivityTypeId.Unsubscribed">
        {{ 'segmentEditor.simpleFilter.controls.commResponseUnsubscribe' | translate }}
      </button>
      <button tuiOption [value]="CommunicationActivityTypeId.Dismiss">
        {{ 'segmentEditor.simpleFilter.controls.commResponseDismiss' | translate }}
      </button>
    </tui-data-list>
  </tui-select>

  <tui-hosted-dropdown
    [content]="linkSelectionDropdown"
    [canOpen]="!disabled"
    [class.disabled]="disabled"
    *ngIf="state?.responseTypeId === CommunicationActivityTypeId.Click"
  >
    <a tabindex="0" tuiLink>
      {{ clickResponseLinkDescription | translate: { count: this.state?.links?.length } }}
      <tui-svg src="tuiIconChevronDown"></tui-svg>
    </a>
  </tui-hosted-dropdown>

  <ng-template #linkSelectionDropdown>
    <div class="response-links-dropdown">
      <div class="response-links">
        <tui-tag
          *ngFor="let link of state?.links; let index = index"
          size="l"
          [hoverable]="true"
          [removable]="true"
          [value]="link"
          (edited)="handleLinkEdit($event, index)"
        ></tui-tag>
      </div>
      <tui-input-inline
        [class.tui-space_top-2]="state?.links?.length"
        [formControl]="linkInput"
        (keyup.enter)="addLink()"
      >
        {{ 'segmentEditor.simpleFilter.controls.commResponseAddLinkPlaceholder' | translate }}
      </tui-input-inline>
    </div>
  </ng-template>
</div>

<ng-template #responseTypeValueContent let-selected>
  <ng-container *ngIf="selected === CommunicationActivityTypeId.Sent">
    {{ 'segmentEditor.simpleFilter.controls.commResponseSent' | translate }}
  </ng-container>
  <ng-container *ngIf="selected === CommunicationActivityTypeId.Delivered">
    {{ 'segmentEditor.simpleFilter.controls.commResponseDelivered' | translate }}
  </ng-container>
  <ng-container *ngIf="selected === CommunicationActivityTypeId.Opened">
    {{ 'segmentEditor.simpleFilter.controls.commResponseOpened' | translate }}
  </ng-container>
  <ng-container *ngIf="selected === CommunicationActivityTypeId.Click">
    {{ 'segmentEditor.simpleFilter.controls.commResponseLinkClick' | translate }}
  </ng-container>
  <ng-container *ngIf="selected === CommunicationActivityTypeId.Spam">
    {{ 'segmentEditor.simpleFilter.controls.commResponseSpam' | translate }}
  </ng-container>
  <ng-container *ngIf="selected === CommunicationActivityTypeId.Unsubscribed">
    {{ 'segmentEditor.simpleFilter.controls.commResponseUnsubscribe' | translate }}
  </ng-container>
  <ng-container *ngIf="selected === CommunicationActivityTypeId.Dismiss">
    {{ 'segmentEditor.simpleFilter.controls.commResponseDismiss' | translate }}
  </ng-container>
</ng-template>

<div class="communication-control">
  @if (!disabled) {
    <tui-multi-select
      [ngModel]="state?.communications ?? []"
      [disabled]="disabled"
      [valueContent]="
        'segmentEditor.simpleFilter.controls.lookupAnyOf' | translate: { count: state?.communications?.length || 0 }
      "
      [editable]="false"
      [tuiTextfieldLabelOutside]="true"
      (ngModelChange)="updateCommunications($event)"
      [tuiTextfieldCleaner]="true"
    >
      {{ 'segmentEditor.simpleFilter.controls.commResponseInEveryCommunication' | translate }}
      <ng-container *tuiDataList>
        <tui-input
          tuiTextfieldIconLeft="tuiIconSearchLarge"
          class="tui-space_all-2"
          #input
          [tuiTextfieldLabelOutside]="true"
          [(ngModel)]="search"
        >
          {{ 'segmentEditor.simpleFilter.controls.lookupSearchInputLabel' | translate }}
          <input tuiTextfield type="text" />
        </tui-input>

        <tui-filter
          class="channel-filters"
          [ngModel]="selectedChannelFilters"
          (ngModelChange)="channelFilterUpdate.next($event)"
          size="s"
          [items]="filterItems"
          [content]="filterContent"
        ></tui-filter>

        <hr />

        <tui-data-list *tuiLet="getItems() | async as items" tuiMultiSelectGroup>
          @for (item of items || [] | tuiFilter: filterMatcher : search; track item.Id) {
            <button tuiOption [value]="item.Id">
              {{ item.Name | translate }}
            </button>
          }
        </tui-data-list>
      </ng-container>
    </tui-multi-select>
  } @else {
    <tui-hosted-dropdown [content]="selectedCommunicationsDropdown" [canOpen]="!!state?.communications?.length">
      <a tuiLink class="ml-4" [ngClass]="{ 'opacity-70': !state?.communications?.length }" tuiHostedDropdownHost>
        {{
          'segmentEditor.simpleFilter.controls.commResponseCountCommunications'
            | translate: { count: state?.communications?.length || 0 }
        }}
      </a>
    </tui-hosted-dropdown>
  }

  <div class="period-controls">
    <tui-hosted-dropdown [canOpen]="!disabled" [class.disabled]="disabled" [content]="periodSetupDropdown">
      <a tabindex="0" tuiLink>
        {{ stateDescription | translate: stateDescriptionParams }}
        <tui-svg src="tuiIconChevronDown"></tui-svg>
      </a>
    </tui-hosted-dropdown>

    @if (state?.period && !disabled) {
      <tui-svg (click)="clearPeriod()" src="tuiIconClose"></tui-svg>
    }
  </div>

  <ng-template #periodSetupDropdown>
    <knk-date-selector
      [enableNotFilledInOption]="false"
      [date]="state?.period ?? null"
      (dateChange)="updatePeriod($event)"
    ></knk-date-selector>
  </ng-template>
</div>

<ng-template #selectedCommunicationsDropdown>
  <tui-data-list>
    @for (item of selectedCommunications(); track item.Id) {
      @if (state?.communications?.includes(item.Id)) {
        <a tuiOption [value]="item.Id" [knkTenantRouterLink]="['communications', 'templates', item.Id]">
          {{ item.Name }}
        </a>
      }
    }
  </tui-data-list>
</ng-template>
