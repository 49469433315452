<knk-widget-card *ngIf="segment$ | async" widgetGroup="segment"></knk-widget-card>

<ng-template #actionMenu>
  @if (!isSegmentExternal()) {
    <tui-hosted-dropdown [content]="dropdown">
      <button appearance="icon" size="m" tuiIconButton type="button" icon="tuiIconMoreVertical"></button>
    </tui-hosted-dropdown>
  }

  <ng-template #dropdown>
    <tui-data-list>
      <button tuiOption (click)="recount()">
        {{ 'Recount' | translate }}
      </button>
      <button
        *knkRestrictAccess="{
          Operation: { Type: 1, Name: 'EditSegment' },
          Ui: { Section: 'SegmentCard' }
        }"
        tuiOption
        (click)="edit()"
      >
        {{ 'Edit' | translate }}
      </button>
      <button
        *knkRestrictAccess="{
          Operation: { Type: 2, Name: 'CreateSegmentRecountJob' },
          Ui: { Section: 'SegmentCard' }
        }"
        tuiOption
        (click)="addRecountJob()"
      >
        {{ 'Add recount job' | translate }}
      </button>
      @for (job of recountJobs$ | async; track job.id; let index = $index) {
        <button
          *knkRestrictAccess="{
            Operation: { Type: 1, Name: 'EditSegmentRecountJob' },
            Ui: { Section: 'SegmentCard' }
          }"
          tuiOption
          (click)="editRecountJob(job.id, job.cron)"
        >
          {{ ('Edit Recount Job' | translate) + index > 0 ? index + 1 : '' }}
        </button>
      }
    </tui-data-list>
  </ng-template>
</ng-template>
