import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LogicalOperator } from '@konnektu/metastore';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  TUI_DEFAULT_MATCHER,
  TuiActiveZoneModule,
  TuiFilterPipeModule,
  TuiLetModule
} from '@taiga-ui/cdk';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiGroupModule,
  TuiHostedDropdownModule,
  TuiPrimitiveTextfieldModule,
  TuiSvgModule,
  TuiTextfieldControllerModule
} from '@taiga-ui/core';
import { TuiMultiSelectModule } from '@taiga-ui/kit';
import { ConditionBlockDef, ConditionDef } from '../models';

@Component({
  selector: 'knk-simple-filter-condition-dropdown',
  templateUrl: 'condition-dropdown.component.html',
  styleUrls: ['condition-dropdown.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    TuiButtonModule,
    TuiSvgModule,
    TuiFilterPipeModule,
    TuiLetModule,
    TuiGroupModule,
    TuiActiveZoneModule,
    TuiMultiSelectModule,
    TuiHostedDropdownModule,
    TuiPrimitiveTextfieldModule,
    TuiTextfieldControllerModule,
    TuiDataListModule
  ]
})
export class ConditionDropdownComponent {
  private readonly translate = inject(TranslateService);

  @Input() canGroupOptions = false;

  @Input() conditionBlocks: ConditionBlockDef[] = [];

  @Output() applyConditions = new EventEmitter<{
    items: ConditionDef[];
    groupWith?: LogicalOperator;
  }>();

  LogicalOperator = LogicalOperator;

  open = false;

  search = '';

  selectedConditions: ConditionDef[] = [];

  readonly filter = (item: ConditionDef, search: string) =>
    TUI_DEFAULT_MATCHER(this.translate.instant(item.label), search);

  apply(groupWith?: LogicalOperator) {
    this.applyConditions.emit({
      items: this.selectedConditions,
      groupWith
    });
    this.selectedConditions = [];
    this.search = '';
    this.open = false;
  }
}
