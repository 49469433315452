import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  Injector,
  OnInit,
  TemplateRef,
  ViewChild,
  computed,
  inject,
  signal
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  DataType,
  MetastoreService,
  from,
  getTableMetaByPath
} from '@konnektu/metastore';
import { SectionV2Module } from '@konnektu/section';
import {
  SEGMENT_TYPE_TO_TRANSLATION,
  SegmentResponseDto,
  SegmentsService
} from '@konnektu/segments-data';
import { TopBarService } from '@konnektu/sidebar-layout';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  TuiTableModule,
  TuiTablePagination,
  TuiTablePaginationModule
} from '@taiga-ui/addon-table';
import {
  TuiActiveZoneModule,
  TuiDestroyService,
  TuiLetModule,
  tuiIsPresent
} from '@taiga-ui/cdk';
import {
  TuiAlertService,
  TuiButtonModule,
  TuiDataListModule,
  TuiDialogService,
  TuiDropdownModule,
  TuiFormatDatePipeModule,
  TuiHintModule,
  TuiHostedDropdownModule,
  TuiLinkModule,
  TuiLoaderModule,
  TuiScrollbarModule,
  TuiSvgModule,
  TuiTextfieldControllerModule
} from '@taiga-ui/core';
import { TuiInputModule } from '@taiga-ui/kit';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {
  catchError,
  combineLatest,
  concatMap,
  debounceTime,
  filter,
  forkJoin,
  map,
  of,
  shareReplay,
  switchMap,
  tap
} from 'rxjs';
import {
  SegmentEditorComponent,
  SegmentEditorDialogResult
} from '../segment-editor/segment-editor.component';
import { CopySegmentDialogComponent } from './copy-segment-dialog.component';

type SortColumn = 'CreatedOn' | 'CreatedOnDesc' | 'Name' | 'NameDesc';

const COLUMN_MAP = {
  timestamp: 'CreatedOn',
  name: 'Name'
};

@Component({
  selector: 'knk-segments',
  templateUrl: 'segments-section.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    TuiLoaderModule,
    TuiTableModule,
    TuiTablePaginationModule,
    TuiLetModule,
    TuiScrollbarModule,
    TuiInputModule,
    TuiTextfieldControllerModule,
    TuiLinkModule,
    TuiHintModule,
    TuiButtonModule,
    InfiniteScrollModule,
    TranslateModule,
    TuiHostedDropdownModule,
    TuiDropdownModule,
    TuiDataListModule,
    TuiSvgModule,
    TuiActiveZoneModule,
    TuiFormatDatePipeModule,
    SectionV2Module
  ],
  providers: [TuiDestroyService],
  styleUrls: ['segments-section.component.scss']
})
export class SegmentsSectionComponent implements OnInit {
  @ViewChild('topBarButton', { static: true }) topBarButton!: TemplateRef<void>;

  private readonly router = inject(Router);

  private readonly segment = inject(SegmentsService);

  private readonly tuiDialog = inject(TuiDialogService);

  private readonly route = inject(ActivatedRoute);

  private readonly injector = inject(Injector);

  private readonly alert = inject(TuiAlertService);

  private readonly translate = inject(TranslateService);

  private readonly topBarService = inject(TopBarService);

  private readonly destroy = inject(DestroyRef);

  readonly metadataProvider$ = inject(MetastoreService)
    .metadata({
      ui: { section: 'NewSegmentPage' }
    })
    .pipe(shareReplay(1));

  readonly createSegmentDialog = this.tuiDialog.open<SegmentEditorDialogResult>(
    new PolymorpheusComponent(SegmentEditorComponent, this.injector),
    {
      size: 'page'
    }
  );

  protected readonly page = signal(0);

  protected readonly pageSize = signal(50);

  protected readonly pagination = computed(() => ({
    page: this.page(),
    size: this.pageSize()
  }));

  protected readonly pagintion$ = toObservable(this.pagination);

  protected readonly sortColumn = signal<string | null>('timestamp');

  protected readonly sortDirection = signal<1 | -1>(-1);

  protected readonly sort = computed(() => ({
    column: this.sortColumn(),
    direction: this.sortDirection()
  }));

  protected readonly sort$ = toObservable(this.sort);

  protected readonly name = signal('');

  protected readonly debouncedName$ = toObservable(this.name).pipe(
    debounceTime(700)
  );

  protected readonly loading = signal(false);

  protected readonly previousSegments = signal<SegmentResponseDto[]>([]);

  protected readonly data$ = combineLatest([
    this.sort$,
    this.debouncedName$
  ]).pipe(
    tap(() => {
      this.loading.update(() => true);
      this.page.update(() => 0);
    }),
    switchMap(([sort, name]) => {
      let orderBy: SortColumn | undefined;
      if (sort?.column) {
        const sortColumn = COLUMN_MAP[sort.column as 'timestamp' | 'name'];
        orderBy = (
          sort.direction === 1 ? sortColumn : `${sortColumn}Desc`
        ) as SortColumn;
      }
      this.previousSegments.update(() => []);
      return this.pagintion$.pipe(
        switchMap((pagination) =>
          this.segment
            .allSegments(pagination.page + 1, pagination.size, orderBy, name)
            .pipe(
              map((val) => {
                this.previousSegments.update((prev) => [...prev, ...val.items]);
                return {
                  items: this.previousSegments(),
                  totalCount: val.totalCount
                };
              }),
              tap(() => this.loading.update(() => false)),
              catchError(() => {
                this.loading.update(() => false);
                return of({ items: [], totalCount: 0 });
              })
            )
        )
      );
    })
  );

  dropdownOpenOnItem: SegmentResponseDto | null = null;

  protected readonly SEGMENT_TYPE_TO_TRANSLATION = SEGMENT_TYPE_TO_TRANSLATION;

  ngOnInit() {
    this.topBarService.configureTopBar(
      { actionTemplate: this.topBarButton },
      this.destroy
    );
  }

  updatePagination(pagination: TuiTablePagination) {
    this.page.update(() => pagination.page);
    this.pageSize.update(() => pagination.size);
  }

  updateSort(sort: { column: string | null; direction: 1 | -1 }) {
    this.sortColumn.update(() => sort.column);
    this.sortDirection.update(() => sort.direction);
  }

  updateName(name: string) {
    this.name.update(() => name);
  }

  activeZoneChange(event: boolean, item: SegmentResponseDto) {
    if (event) {
      this.dropdownOpenOnItem = item;
    } else {
      this.dropdownOpenOnItem = null;
    }
  }

  redirectToSegment(seg: SegmentResponseDto): void {
    void this.router.navigate([seg.id], { relativeTo: this.route });
  }

  createNew(): void {
    this.createSegmentDialog
      .pipe(
        filter(tuiIsPresent),
        takeUntilDestroyed(this.destroy),
        switchMap((value) => {
          const simpleFilterState = value.simpleFilterState;
          const formValue = value.form;

          const serializedQuery = from(formValue.entityName as string)
            .select(formValue.selectedFields as string[])
            .where(formValue.expression ?? undefined)
            .done();

          return (
            formValue.indexedFields?.length
              ? forkJoin(
                  formValue.indexedFields.map((f) =>
                    getTableMetaByPath(
                      this.metadataProvider$,
                      formValue.entityName as string,
                      f.split('.')
                    ).pipe(map((meta) => ({ meta, field: f })))
                  )
                )
              : of([])
          ).pipe(
            concatMap((cols) => {
              const indexColumns = cols
                .filter((c) => typeof c.meta === 'number')
                .map((c) => ({
                  name: c.field,
                  type: c.meta as DataType
                }));
              return this.segment.createSegment({
                name: formValue.name as string,
                ...(simpleFilterState
                  ? { metadata: { simpleFilterState } }
                  : {}),
                typeId: formValue.typeId,
                query: {
                  text: {
                    parameters: serializedQuery.parameters,
                    query: {
                      $from: serializedQuery.query.$from,
                      $select: serializedQuery.query.$select,
                      $where: serializedQuery.query.$where
                    }
                  },
                  source: 1
                },
                index: {
                  columns: indexColumns,
                  unique: formValue.isIndexUnique as boolean
                }
              });
            })
          );
        })
      )
      .subscribe();
  }

  copySegment(segment: SegmentResponseDto) {
    this.tuiDialog
      .open<SegmentResponseDto | null | Error>(
        new PolymorpheusComponent(CopySegmentDialogComponent),
        {
          data: { segment }
        }
      )
      .pipe(takeUntilDestroyed(this.destroy), filter(tuiIsPresent))
      .subscribe({
        next: (result) => {
          if (result instanceof Error) {
            this.alert
              .open(this.translate.instant('segmentSection.copyFail'), {
                status: 'error'
              })
              .pipe(takeUntilDestroyed(this.destroy))
              .subscribe();
          } else {
            result.query.text = JSON.parse(result.query.text);
          }
        }
      });
  }

  openDropdownOnSegment(event: MouseEvent, segment: SegmentResponseDto) {
    event.stopPropagation();
    this.dropdownOpenOnItem = segment;
  }
}
