import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  inject
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LogicalOperator } from '@konnektu/metastore'; // Добавлен импорт MetastoreOperator
import { TranslateModule } from '@ngx-translate/core';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiScrollbarModule
} from '@taiga-ui/core';
import { TuiSelectModule } from '@taiga-ui/kit';
import { ConditionControlComponent } from './condition-control/condition-control.component';
import { ConditionDropdownComponent } from './condition-dropdown/condition-dropdown.component';
import { ConditionGroupComponent } from './condition-group/condition-group.component';
import { ConditionDef, SimpleFilterTemplate } from './models';
import { SimpleFilterTemplateStore } from './template-store';

export interface SimpleFilterGroup {
  operator: LogicalOperator.and | LogicalOperator.or;
  items: (SimpleFilterCondition | SimpleFilterGroup)[];
}

export interface SimpleFilterCondition {
  state: any;
  expression: any;
  isValid: (state: any) => boolean;
  definitionId: string;
}

export type SimpleFilterState = SimpleFilterCondition | SimpleFilterGroup;

@Component({
  selector: 'knk-segment-simple-filter',
  templateUrl: 'simple-filter.component.html',
  styleUrls: ['simple-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    TuiSelectModule,
    TuiDataListModule,
    TuiButtonModule,
    TuiScrollbarModule,
    ConditionGroupComponent,
    ConditionControlComponent,
    ConditionDropdownComponent
  ]
})
export class SimpleFilterComponent {
  protected readonly templateStore = inject(SimpleFilterTemplateStore);

  @Input() disabled = false;

  @Input() template: SimpleFilterTemplate | null = null;

  @Input() set state(val: SimpleFilterState) {
    this.simpleFilterState = val;
  }

  @Output() stateChange = new EventEmitter<SimpleFilterState | null>();

  @Output() expressionChange = new EventEmitter<object | null>();

  @Output() templateChange = new EventEmitter<SimpleFilterTemplate>();

  LogicalOperator = LogicalOperator;

  get templates() {
    return this.templateStore.templates;
  }

  get conditionDefs() {
    return this.templateStore.conditionDefinitions;
  }

  get conditionBlocks() {
    return this.template?.conditionBlocks ?? [];
  }

  simpleFilterState: SimpleFilterState | null = null;

  isGroup = (exp: any): exp is SimpleFilterGroup => 'items' in exp;

  isCondition = (exp: any): exp is SimpleFilterCondition =>
    'definitionId' in exp;

  selectTemplate(template: SimpleFilterTemplate) {
    this.template = template;
    this.simpleFilterState = null;
    this.templateChange.emit(this.template);
    this.expressionChange.emit(this.getResultExpression());
    this.stateChange.emit(this.simpleFilterState);
  }

  getConditionDef(id: string) {
    return this.conditionDefs.find((d) => d.id === id);
  }

  getExpressionFromState(state: SimpleFilterState): any {
    if (this.isCondition(state)) {
      return state.expression;
    }
    if (this.isGroup(state)) {
      const itemsExpression = state.items
        .map((i) => this.getExpressionFromState(i))
        .filter((e) => e !== null);
      if (itemsExpression.length === 0) {
        return null;
      }
      return {
        [state.operator]: itemsExpression
      };
    }
  }

  getResultExpression() {
    if (this.simpleFilterState === null) {
      return null;
    }
    let resultExpression = null;
    if (this.isCondition(this.simpleFilterState)) {
      const expressionFromState = this.getExpressionFromState(
        this.simpleFilterState
      );
      if (expressionFromState === null) {
        return this.template?.additionalFilter || null;
      }
      if (this.template?.additionalFilter) {
        resultExpression = {
          [LogicalOperator.and]: [
            expressionFromState,
            this.template.additionalFilter
          ]
        };
      } else {
        resultExpression = expressionFromState;
      }
    }
    if (this.isGroup(this.simpleFilterState)) {
      const expressionFromFilters = this.getExpressionFromState(
        this.simpleFilterState
      );
      if (expressionFromFilters === null) {
        return this.template?.additionalFilter || null;
      }
      if (this.template?.additionalFilter) {
        resultExpression = {
          [LogicalOperator.and]: [
            expressionFromFilters,
            this.template.additionalFilter
          ]
        };
      } else {
        resultExpression = expressionFromFilters;
      }
    }
    return resultExpression;
  }

  updateState(state: SimpleFilterState) {
    this.simpleFilterState = state;
    this.stateChange.emit(this.simpleFilterState);
    this.expressionChange.emit(this.getResultExpression());
  }

  addConditions(items: ConditionDef[], groupWith?: LogicalOperator) {
    if (!this.simpleFilterState) {
      if (items.length === 0) {
        this.simpleFilterState = {
          operator: groupWith || LogicalOperator.and,
          items: []
        };
      } else if (items.length > 1) {
        this.simpleFilterState = {
          operator: groupWith || LogicalOperator.and,
          items: items.map((def) => ({
            definitionId: def.id,
            expression: null,
            isValid: () => true,
            state: null
          }))
        };
      } else {
        this.simpleFilterState = {
          definitionId: items[0].id,
          expression: null,
          isValid: () => true,
          state: null
        };
      }
    } else {
      if (this.isCondition(this.simpleFilterState) && items.length === 1) {
        this.simpleFilterState = {
          operator: LogicalOperator.and,
          items: [
            this.simpleFilterState,
            ...items.map((def) => ({
              definitionId: def.id,
              expression: null,
              isValid: () => true,
              state: null
            }))
          ]
        };
      } else if (this.isCondition(this.simpleFilterState) && groupWith) {
        this.simpleFilterState = {
          operator: LogicalOperator.and,
          items: [
            this.simpleFilterState,
            {
              operator: groupWith,
              items: items.map((def) => ({
                definitionId: def.id,
                expression: null,
                isValid: () => true,
                state: null
              }))
            }
          ]
        };
      } else if (
        this.isCondition(this.simpleFilterState) &&
        !groupWith &&
        items.length > 1
      ) {
        this.simpleFilterState = {
          operator: LogicalOperator.and,
          items: [
            this.simpleFilterState,
            ...items.map((def) => ({
              definitionId: def.id,
              expression: null,
              isValid: () => true,
              state: null
            }))
          ]
        };
      }
    }
  }

  resetFilter() {
    this.simpleFilterState = null;
    this.stateChange.emit(this.simpleFilterState);
    this.expressionChange.emit(this.getResultExpression());
  }
}
