<knk-common-dialog (cancelClicked)="cancel()" (doneClicked)="done()">
  <div class="knk-segment-copy-dialog">
    <knk-text-input
      [(ngModel)]="name"
      [label]="'Name' | translate"
      [placeholder]="'Name' | translate"
    ></knk-text-input>

    <div class="knk-segment-copy-dialog__filter">
      <knk-dropdown
        (ngModelChange)="from$.next($event)"
        [disabled]="currentVersion !== null"
        [label]="'Entity name' | translate"
        [ngModel]="from$ | async"
        [options]="(fromTableOptions$ | async)!"
        [placeholder]="'Entity name' | translate"
      ></knk-dropdown>

      <knk-field-selector
        (fieldsChanged)="fields = $any($event)"
        (indexedFieldsChanged)="indexedColumns = $event"
        [(isIndexUnique)]="isIndexUnique"
        [entityName]="(from$ | async)!"
        [fields]="fields"
        [indexedFields]="indexedColumns"
        [isEntityNameAndFieldSelectorBlocked]="currentVersion !== null"
        [metadataProvider]="metadataProvider$"
        [useSelectIndexes]="true"
      ></knk-field-selector>

      <knk-expression-builder
        (expressionChanged)="filterExpression = $event"
        [entityName]="(from$ | async)!"
        [expression]="filterExpression"
        [metadataProvider]="metadataProvider$"
        [showApply]="false"
      ></knk-expression-builder>
    </div>
  </div>
</knk-common-dialog>
