import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isDefined } from '@konnektu/helpers';
import { SectionDataConstructor } from '@konnektu/section';
import { SegmentVersionDto, SegmentsService } from '@konnektu/segments-data';
import { filter, map, switchMap } from 'rxjs';

@Component({
  selector: 'knk-segment-versions-section',
  templateUrl: 'segment-versions-section.component.html',
  styleUrls: ['segment-versions-section.component.scss']
})
export class SegmentVersionsSectionComponent {
  private readonly segmentService = inject(SegmentsService);
  private readonly route = inject(ActivatedRoute);

  getSegmentVersions: SectionDataConstructor<SegmentVersionDto> = ({
    page,
    size
  }) =>
    this.route.paramMap.pipe(
      map((paramMap) => paramMap.get('segmentId')),
      filter(isDefined),
      map((segmentId) => parseInt(segmentId, 10)),
      filter((id) => !!id),
      switchMap((id) =>
        this.segmentService
          .segmentVersions(id, page + 1, size)
          .pipe(map((r) => ({ items: r.items, totalCount: r.totalCount })))
      )
    );
}
