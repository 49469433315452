@if (!disabled) {
  <tui-multi-select
    [ngModel]="state"
    [disabled]="disabled"
    [valueContent]="'segmentEditor.simpleFilter.controls.lookupAnyOf' | translate: { count: state?.length || 0 }"
    [editable]="false"
    (ngModelChange)="updateState($event)"
    [tuiTextfieldCleaner]="true"
  >
    {{ definition.label | translate }}
    <ng-template tuiDataList>
      <tui-input
        tuiTextfieldIconLeft="tuiIconSearchLarge"
        class="tui-space_all-2"
        [tuiTextfieldLabelOutside]="true"
        [ngModel]="search$.value"
        (ngModelChange)="search$.next($event)"
      >
        {{ 'segmentEditor.simpleFilter.controls.lookupSearchInputLabel' | translate }}
      </tui-input>
      <tui-data-list *ngIf="items$ | async as items; else loading" tuiMultiSelectGroup>
        <button *ngFor="let item of items" tuiOption [value]="item.Id">
          {{ item.Name | translate }}
        </button>
      </tui-data-list>
      <ng-template #loading>
        <tui-loader class="tui-space_vertical-4"></tui-loader>
      </ng-template>
    </ng-template>
  </tui-multi-select>
} @else {
  <tui-hosted-dropdown [content]="selectedItemsDropdown" [canOpen]="!!state?.length">
    <div class="flex flex-col gap-1">
      <span class="text-text02">{{ definition.label | translate }}</span>
      <a tuiLink [ngClass]="{ 'opacity-70': !state?.length }" tuiHostedDropdownHost>
        {{ 'segmentEditor.simpleFilter.controls.lookupAnyOf' | translate: { count: state?.length || 0 } }}
      </a>
    </div>
  </tui-hosted-dropdown>

  <ng-template #selectedItemsDropdown>
    <tui-data-list>
      @for (item of selectedItems(); track item.Id) {
        @if (state?.includes(item.Id)) {
          <a tuiOption [value]="item.Id">
            {{ item.Name }}
          </a>
        }
      }
    </tui-data-list>
  </ng-template>
}
