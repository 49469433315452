import { Component, inject } from '@angular/core';
import { DialogComponent } from '@konnektu/components';
import { JobsService } from '@konnektu/jobs';
import { TranslateService } from '@ngx-translate/core';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';

export type EditScheduledResult = 'remove' | { cron: string } | 'cancel';

@Component({
  selector: 'knk-edit-schedule',
  templateUrl: 'edit-schedule.dialog.html',
  styleUrls: ['edit-schedule.dialog.scss']
})
export class EditScheduleDialogComponent extends DialogComponent<EditScheduledResult> {
  private readonly jobs = inject(JobsService);

  private readonly translate = inject(TranslateService);

  private readonly context = inject<
    TuiDialogContext<EditScheduledResult, { scheduleId: string; cron: string }>
  >(POLYMORPHEUS_CONTEXT, { optional: true });

  scheduleId: string | null = this.context?.data?.scheduleId || null;

  cron: string | null = this.context?.data?.cron || null;

  readonly scheduled$ = this.jobs.requestAllScheduled(
    'jobs.segments.calculate'
  );

  done() {
    if (this.cron) {
      this.context?.completeWith({ cron: this.cron });
    }
  }

  removeJob() {
    this.context?.completeWith('remove');
  }
}
