import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AcsService } from '@konnektu/acs-data';
import { map, shareReplay } from 'rxjs';
import { SegmentCardComponentStore } from '../../store';

@Component({
  selector: 'knk-segment-versions-widget',
  templateUrl: 'versions.widget.html',
  styleUrls: ['version.widget.scss']
})
export class SegmentVersionsWidgetComponent {
  private readonly componentStore = inject(SegmentCardComponentStore);
  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);

  versions$ = this.componentStore.versions$;

  canLoadVersionSnapshot$ = this.acs
    .hasAccess([
      {
        Ui: { Section: 'SegmentCard' },
        Operation: { Name: 'LoadVersionSnapshot' }
      }
    ])
    .pipe(
      map(([canLoadVersionSnapshot]) => canLoadVersionSnapshot),
      shareReplay(1)
    );

  constructor(private readonly acs: AcsService) {}

  redirectToVersionsSection(): void {
    void this.router.navigate(['versions'], { relativeTo: this.route });
  }
}
