import { Component, inject } from '@angular/core';
import { SegmentTypeIds } from '@konnektu/domain-models';
import { isDefined } from '@konnektu/helpers';
import { SegmentResponseDto } from '@konnektu/segments-data';
import { Observable, filter } from 'rxjs';
import { SegmentCardComponentStore } from '../../store';

@Component({
  selector: 'knk-segment-info-widget',
  templateUrl: 'info.widget.html',
  styleUrls: ['info.widget.scss']
})
export class SegmentInfoWidgetComponent {
  private readonly componentStore = inject(SegmentCardComponentStore);

  SegmentTypeIds = SegmentTypeIds;

  entity$: Observable<SegmentResponseDto> = this.componentStore.segment$.pipe(
    filter(isDefined)
  );

  indexedColumns$ = this.componentStore.indexedColumns$;
}
