import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LogicalOperator, MetastoreOperator } from '@konnektu/metastore';
import { TranslateModule } from '@ngx-translate/core';
import {
  TuiDropdownModule,
  TuiHostedDropdownModule,
  TuiLinkModule,
  TuiPrimitiveTextfieldModule,
  TuiSvgModule,
  TuiTextfieldControllerModule
} from '@taiga-ui/core';
import {
  TUI_ARROW,
  TuiCheckboxLabeledModule,
  TuiInputRangeModule
} from '@taiga-ui/kit';
import { NumberRangeConditionDef } from '../models';
import { SimpleFilterControl } from './simple-filter-control';

@Component({
  selector: 'knk-simple-filter-number-range-control',
  template: `
    <tui-hosted-dropdown
      [content]="content"
      [canOpen]="!disabled"
      [(open)]="open"
    >
      <tui-primitive-textfield
        [value]="state ? (stateDescription | translate: state) : ''"
        (valueChange)="changeValue($event)"
        [editable]="false"
        [tuiTextfieldIcon]="arrow"
        [tuiTextfieldCleaner]="true"
      >
        {{ definition.label | translate }}
        <input tuiTextfield style="cursor: pointer" />
      </tui-primitive-textfield>
    </tui-hosted-dropdown>

    <ng-template #content>
      <div
        style="padding: var(--tui-padding-m); display: flex; flex-direction: column; gap: 0.5rem"
      >
        <tui-input-range
          style="width: 200px"
          [ngModel]="[state?.from || 0, state?.to || 100]"
          (ngModelChange)="updateRange($event)"
          [min]="definition.min || 0"
          [max]="definition.max || 100"
          [tuiTextfieldLabelOutside]="true"
        >
        </tui-input-range>
      </div>
    </ng-template>
  `,
  styles: [
    `
      :host {
        display: flex;
      }

      tui-hosted-dropdown {
        width: 300px;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    TranslateModule,
    TuiInputRangeModule,
    TuiHostedDropdownModule,
    TuiDropdownModule,
    TuiSvgModule,
    TuiLinkModule,
    TuiCheckboxLabeledModule,
    TuiTextfieldControllerModule,
    TuiPrimitiveTextfieldModule
  ]
})
export class NumberRangeControlComponent
  extends SimpleFilterControl<NumberRangeConditionDef>
  implements OnInit
{
  open = false;

  arrow = TUI_ARROW;

  get stateDescription() {
    return 'segmentEditor.simpleFilter.controls.numberRange';
  }

  ngOnInit(): void {
    this.updateStateAndExpression(this.state || null);
  }

  parseState(state: object) {
    if (
      'from' in state &&
      typeof state.from === 'number' &&
      'to' in state &&
      typeof state.to === 'number'
    ) {
      return { from: state.from, to: state.to };
    }
    return null;
  }

  updateRange([from, to]: [number, number]) {
    this.updateStateAndExpression({ ...this._state, from, to });
  }

  changeValue(event: string) {
    if (event === '') {
      this.updateStateAndExpression(null);
    }
  }

  createExpression(column: string, state: typeof this.state): object {
    if (!state) {
      return {};
    }
    return {
      [LogicalOperator.and]: [
        {
          [column]: {
            [MetastoreOperator.gte]: state.from
          }
        },
        {
          [column]: {
            [MetastoreOperator.lte]: state.to
          }
        }
      ]
    };
  }
}
