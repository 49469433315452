import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  and,
  equal,
  greaterThanOrEqual,
  isNull,
  lessThanOrEqual
} from '@konnektu/metastore';
import { TranslateModule } from '@ngx-translate/core';
import { TuiDay, TuiDayRange } from '@taiga-ui/cdk';
import {
  TuiButtonModule,
  TuiDropdownModule,
  TuiHostedDropdownModule,
  TuiLinkModule,
  TuiNotificationModule,
  TuiPrimitiveTextfieldModule,
  TuiSvgModule,
  TuiTextfieldControllerModule
} from '@taiga-ui/core';
import { TUI_ARROW, TuiTabsModule } from '@taiga-ui/kit';
import { DateConditionDef, NotFilledInValue } from '../models';
import { DateSelectorComponent } from './date-selector/date-selector.component';
import { SimpleFilterControl } from './simple-filter-control';

export class DynamicDate {
  constructor(public daysAgo: number) {}
}

@Component({
  selector: 'knk-simple-filter-date-control',
  template: `
    <tui-hosted-dropdown
      [class.empty]="state === null"
      tuiDropdownAlign="left"
      [content]="calendar"
      [canOpen]="!disabled"
      [(open)]="open"
    >
      <tui-primitive-textfield
        [value]="stateDescription | translate: stateDescriptionParams"
        (valueChange)="changeValue($event)"
        [editable]="false"
        [disabled]="disabled"
        [tuiTextfieldIcon]="arrow"
        [tuiTextfieldCleaner]="true"
      >
        {{ definition.label | translate }}
      </tui-primitive-textfield>
    </tui-hosted-dropdown>

    <ng-template #calendar>
      <knk-date-selector
        [date]="state"
        (dateChange)="onDateUpdate($event)"
      ></knk-date-selector>
    </ng-template>
  `,
  styles: [
    `
      :host {
        display: flex;
      }

      tui-hosted-dropdown {
        width: 300px;
      }

      tui-hosted-dropdown.empty {
        color: var(--tui-text-03);
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    TuiHostedDropdownModule,
    TuiDropdownModule,
    TuiLinkModule,
    TuiSvgModule,
    TuiTabsModule,
    TuiButtonModule,
    TuiNotificationModule,
    TuiTextfieldControllerModule,
    TuiPrimitiveTextfieldModule,
    DateSelectorComponent
  ]
})
export class DateControlComponent
  extends SimpleFilterControl<DateConditionDef>
  implements OnInit
{
  readonly arrow = TUI_ARROW;

  open = false;

  selectedDate: TuiDay | TuiDayRange | DynamicDate | null = null;

  get stateDescription() {
    if (this.state instanceof NotFilledInValue) {
      return 'segmentEditor.simpleFilter.controls.birthDateNotFilledIn';
    }
    if (this.state instanceof TuiDay) {
      return this.state.getFormattedDay('DMY', '.');
    }
    if (this.state instanceof TuiDayRange) {
      return this.state.getFormattedDayRange('DMY', '.');
    }
    if (this.state instanceof DynamicDate) {
      return 'segmentEditor.simpleFilter.controls.dateDynamicSelected';
    }
    return '';
  }

  get stateDescriptionParams() {
    if (this.state instanceof DynamicDate) {
      return { daysAgo: this.state.daysAgo };
    }
    return {};
  }

  ngOnInit(): void {
    this.updateStateAndExpression(this.state || null);
  }

  changeValue(event: string) {
    if (event === '') {
      this.updateStateAndExpression(null);
    }
  }

  onDateUpdate(date: TuiDay | TuiDayRange | DynamicDate | NotFilledInValue) {
    this.updateStateAndExpression(date);
  }

  parseState(
    state:
      | object
      | TuiDay
      | NotFilledInValue
      | TuiDayRange
      | DynamicDate
      | null
      | undefined
  ): TuiDay | TuiDayRange | NotFilledInValue | DynamicDate | null {
    if (
      state instanceof TuiDay ||
      state instanceof TuiDayRange ||
      state instanceof DynamicDate ||
      state instanceof NotFilledInValue
    ) {
      return state;
    } else {
      if (state === null) {
        return new NotFilledInValue();
      }
      if (!state) {
        return null;
      }
      if (typeof state === 'string') {
        return TuiDay.jsonParse(state);
      }
      if ('from' in state && 'to' in state) {
        return new TuiDayRange(
          TuiDay.jsonParse(state.from as string),
          TuiDay.jsonParse(state.to as string)
        );
      }
      if ('daysAgo' in state) {
        return new DynamicDate(state.daysAgo as number);
      }
    }
    return null;
  }

  createExpression(column: string, state: typeof this._state | undefined) {
    if (!state) {
      return {};
    }
    if (state instanceof TuiDay) {
      return equal(column, state.toUtcNativeDate().toISOString());
    }
    if (state instanceof TuiDayRange) {
      return and(
        greaterThanOrEqual(column, state.from.toUtcNativeDate().toISOString()),
        lessThanOrEqual(column, state.to.toUtcNativeDate().toISOString())
      );
    }
    if (state instanceof NotFilledInValue) {
      return isNull(column);
    } else {
      return equal(column, `$utcnow - $days:${state.daysAgo}`);
    }
  }

  override modifyState(
    state: object | TuiDay | TuiDayRange | DynamicDate | null | undefined
  ): TuiDay | TuiDayRange | DynamicDate | null {
    if (state instanceof TuiDay) {
      if (this.state === null || this.state instanceof DynamicDate) {
        return state;
      }
      if (this.state instanceof TuiDay) {
        if (this.state.dayBefore(state)) {
          return new TuiDayRange(this.state, state);
        }
        if (this.state.dayAfter(state)) {
          return new TuiDayRange(state, this.state);
        }
      }
    }
    return state as TuiDay | TuiDayRange | DynamicDate | null;
  }
}
