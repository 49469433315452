import * as i0 from '@angular/core';
import { ViewContainerRef, Directive, Inject, Input, NgModule } from '@angular/core';
class TuiForDirective {
  constructor(vcr) {
    this.vcr = vcr;
    this.ngForOf = [];
  }
  ngOnChanges() {
    var _a, _b;
    (_a = this.ref) === null || _a === void 0 ? void 0 : _a.destroy();
    if (((_b = this.ngForOf) === null || _b === void 0 ? void 0 : _b.length) === 0 && this.ngForEmpty) {
      this.ref = this.vcr.createEmbeddedView(this.ngForEmpty);
    } else if (!this.ngForOf && this.ngForElse) {
      this.ref = this.vcr.createEmbeddedView(this.ngForElse);
    }
  }
}
TuiForDirective.ɵfac = function TuiForDirective_Factory(t) {
  return new (t || TuiForDirective)(i0.ɵɵdirectiveInject(ViewContainerRef));
};
TuiForDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiForDirective,
  selectors: [["", "ngFor", "", "ngForOf", "", "ngForElse", ""], ["", "ngFor", "", "ngForOf", "", "ngForEmpty", ""]],
  inputs: {
    ngForOf: "ngForOf",
    ngForElse: "ngForElse",
    ngForEmpty: "ngForEmpty"
  },
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiForDirective, [{
    type: Directive,
    args: [{
      selector: '[ngFor][ngForOf][ngForElse],[ngFor][ngForOf][ngForEmpty]'
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef,
      decorators: [{
        type: Inject,
        args: [ViewContainerRef]
      }]
    }];
  }, {
    ngForOf: [{
      type: Input
    }],
    ngForElse: [{
      type: Input
    }],
    ngForEmpty: [{
      type: Input
    }]
  });
})();
class TuiForModule {}
TuiForModule.ɵfac = function TuiForModule_Factory(t) {
  return new (t || TuiForModule)();
};
TuiForModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiForModule
});
TuiForModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiForModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiForDirective],
      exports: [TuiForDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiForDirective, TuiForModule };
