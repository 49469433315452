import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { isNull } from '@konnektu/metastore';
import { TranslateModule } from '@ngx-translate/core';
import {
  TuiButtonModule,
  TuiDropdownModule,
  TuiHostedDropdownModule,
  TuiLinkModule,
  TuiNotificationModule,
  TuiPrimitiveTextfieldModule,
  TuiSvgModule,
  TuiTextfieldControllerModule
} from '@taiga-ui/core';
import {
  TUI_ARROW,
  TuiInputNumberModule,
  TuiRadioBlockModule,
  TuiTabsModule
} from '@taiga-ui/kit';
import { BirthDateConditionDef, NotFilledInValue } from '../models';
import { SimpleFilterControl } from './simple-filter-control';

export class RelativeDate {
  constructor(public days: number) {}
}

@Component({
  selector: 'knk-simple-filter-birth-date-control',
  template: `
    <tui-hosted-dropdown
      [class.empty]="state === null"
      tuiDropdownAlign="left"
      [content]="calendar"
      [canOpen]="!disabled"
    >
      <tui-primitive-textfield
        [value]="stateDescription | translate: stateDescriptionParams"
        [editable]="false"
        [disabled]="disabled"
        [tuiTextfieldIcon]="arrow"
      >
        {{ definition.label | translate }}
      </tui-primitive-textfield>
    </tui-hosted-dropdown>

    <ng-template #calendar>
      <div
        style="padding: var(--tui-padding-m); display: flex; flex-wrap: wrap; gap: 1rem"
      >
        <tui-radio-block
          contentAlign="left"
          [(ngModel)]="selectedRelativeDate"
          (ngModelChange)="onSelect(notFilledInValue)"
          [item]="notFilledInValue"
          size="m"
        >
          {{
            'segmentEditor.simpleFilter.controls.birthDateNotFilledIn'
              | translate
          }}
        </tui-radio-block>

        <tui-radio-block
          contentAlign="left"
          [(ngModel)]="selectedRelativeDate"
          (ngModelChange)="onSelect(todayRelativeDate)"
          [item]="todayRelativeDate"
          size="m"
        >
          {{ 'segmentEditor.simpleFilter.controls.birthDateToday' | translate }}
        </tui-radio-block>

        <tui-radio-block
          contentAlign="left"
          [(ngModel)]="selectedRelativeDate"
          (ngModelChange)="onSelect(yesterdayRelativeDate)"
          [item]="yesterdayRelativeDate"
          size="m"
        >
          {{
            'segmentEditor.simpleFilter.controls.birthDateYesterday' | translate
          }}
        </tui-radio-block>

        <tui-radio-block
          contentAlign="left"
          [(ngModel)]="selectedRelativeDate"
          (ngModelChange)="onSelect(tomorrowRelativeDate)"
          [item]="tomorrowRelativeDate"
          size="m"
        >
          {{
            'segmentEditor.simpleFilter.controls.birthDateTomorrow' | translate
          }}
        </tui-radio-block>

        <tui-radio-block
          contentAlign="left"
          [(ngModel)]="selectedRelativeDate"
          (ngModelChange)="onSelect(pastRelativeDate)"
          [item]="pastRelativeDate"
          size="m"
        >
          <div style="display: flex; gap: 0.5rem; align-items: center">
            <tui-input-number
              [ngModel]="-pastRelativeDate.days"
              (ngModelChange)="updatePastRelativeDateDays($event)"
              style="width: 50px"
              tuiTextfieldSize="s"
              decimal="never"
              [max]="100"
              [min]="0"
            >
            </tui-input-number>
            {{
              'segmentEditor.simpleFilter.controls.dateDynamicDaysAgo'
                | translate
                  : {
                      count: pastRelativeDate.days
                    }
            }}
          </div>
        </tui-radio-block>

        <tui-radio-block
          contentAlign="left"
          [(ngModel)]="selectedRelativeDate"
          (ngModelChange)="onSelect(futureRelativeDate)"
          [item]="futureRelativeDate"
          size="m"
        >
          <div style="display: flex; gap: 0.5rem; align-items: center">
            {{
              'segmentEditor.simpleFilter.controls.birthDateAfter' | translate
            }}
            <tui-input-number
              [ngModel]="futureRelativeDate.days"
              (ngModelChange)="updateFutureRelativeDateDays($event)"
              style="width: 50px"
              tuiTextfieldSize="s"
              decimal="never"
              [max]="100"
              [min]="0"
            >
            </tui-input-number>
            {{
              'segmentEditor.simpleFilter.controls.birthDateDays'
                | translate
                  : {
                      days: futureRelativeDate.days
                    }
            }}
          </div>
        </tui-radio-block>
      </div>
    </ng-template>
  `,
  styles: [
    `
      :host {
        display: flex;
      }

      tui-hosted-dropdown {
        width: 300px;
      }

      tui-hosted-dropdown.empty {
        color: var(--tui-text-03);
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    TuiHostedDropdownModule,
    TuiDropdownModule,
    TuiRadioBlockModule,
    TuiInputNumberModule,
    TuiLinkModule,
    TuiSvgModule,
    TuiTabsModule,
    TuiButtonModule,
    TuiNotificationModule,
    TuiTextfieldControllerModule,
    TuiPrimitiveTextfieldModule
  ]
})
export class BirthDateControlComponent
  extends SimpleFilterControl<BirthDateConditionDef>
  implements OnInit
{
  readonly arrow = TUI_ARROW;

  readonly todayRelativeDate = new RelativeDate(0);

  readonly yesterdayRelativeDate = new RelativeDate(-1);

  readonly tomorrowRelativeDate = new RelativeDate(1);

  readonly pastRelativeDate = new RelativeDate(-7);

  readonly futureRelativeDate = new RelativeDate(7);

  readonly notFilledInValue = new NotFilledInValue();

  selectedRelativeDate: RelativeDate | NotFilledInValue | null = null;

  get stateDescription() {
    if (this.selectedRelativeDate instanceof NotFilledInValue) {
      return 'segmentEditor.simpleFilter.controls.birthDateNotFilledIn';
    }
    if (this.selectedRelativeDate) {
      if (this.selectedRelativeDate.days === 0) {
        return 'segmentEditor.simpleFilter.controls.birthDateToday';
      }
      if (this.selectedRelativeDate.days === 1) {
        return 'segmentEditor.simpleFilter.controls.birthDateTomorrow';
      }
      if (this.selectedRelativeDate.days === -1) {
        return 'segmentEditor.simpleFilter.controls.birthDateYesterday';
      }
      return this.selectedRelativeDate.days > 0
        ? 'segmentEditor.simpleFilter.controls.birthDateFuture'
        : 'segmentEditor.simpleFilter.controls.birthDatePast';
    }
    return '';
  }

  get stateDescriptionParams() {
    if (this.state && this.state instanceof RelativeDate) {
      return { days: Math.abs(this.state.days) };
    }
    return {};
  }

  ngOnInit() {
    if (this.state && this.state instanceof RelativeDate) {
      if (this.state.days === 0) {
        this.selectedRelativeDate = this.todayRelativeDate;
      }
      if (this.state.days === 1) {
        this.selectedRelativeDate = this.tomorrowRelativeDate;
      }
      if (this.state.days === -1) {
        this.selectedRelativeDate = this.yesterdayRelativeDate;
      }
      if (this.state.days < -1) {
        this.selectedRelativeDate = this.pastRelativeDate;
        this.pastRelativeDate.days = this.state.days;
      }
      if (this.state.days > 1) {
        this.selectedRelativeDate = this.futureRelativeDate;
        this.futureRelativeDate.days = this.state.days;
      }
    }
    if (this.state instanceof NotFilledInValue || this.state === null) {
      this.selectedRelativeDate = this.notFilledInValue;
    }
    this.updateStateAndExpression(this.state || null);
  }

  parseState(
    state: object | RelativeDate | null
  ): RelativeDate | NotFilledInValue | null {
    if (state === null) {
      return new NotFilledInValue();
    }
    if (state instanceof RelativeDate || state === null) {
      return state;
    } else {
      if (!state) {
        return null;
      }
      if ('days' in state) {
        return new RelativeDate(state.days as number);
      }
    }
    return null;
  }

  createExpression(column: string, state: typeof this._state | undefined): any {
    if (!state) {
      return null;
    }
    if (state instanceof RelativeDate) {
      return {
        [column]: {
          '$func.birthdayIsWithinDaysFromNow': state.days
        }
      };
    }
    return isNull(column);
  }

  updatePastRelativeDateDays(days: number) {
    this.pastRelativeDate.days = -days;
    this.updateStateAndExpression(this.pastRelativeDate);
  }

  updateFutureRelativeDateDays(days: number) {
    this.futureRelativeDate.days = days;
    this.updateStateAndExpression(this.futureRelativeDate);
  }

  onSelect(date: RelativeDate | NotFilledInValue) {
    this.updateStateAndExpression(date);
  }
}
