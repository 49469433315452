import { Component } from '@angular/core';
import { DialogComponent } from '@konnektu/components';

@Component({
  selector: 'knk-segment-copy-raw',
  templateUrl: 'copy-raw-dialog.component.html',
  styleUrls: ['copy-raw-dialog.component.scss']
})
export class SegmentCopyRawDialogComponent extends DialogComponent<{
  name: string;
  queryText: string;
} | null> {
  name = '';

  rawSql = '';

  cancel(): void {
    this.closeSelf(null);
  }

  done(): void {
    this.closeSelf({
      name: this.name,
      queryText: this.rawSql
    });
  }
}
