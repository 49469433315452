import { Directive, EventEmitter, Input, Output } from '@angular/core';
import {
  BaseConditionDef,
  throwColumnOrExpressionNotSpecified
} from '../models';

@Directive()
export abstract class SimpleFilterControl<
  TDef extends BaseConditionDef<TState>,
  TState = TDef extends BaseConditionDef<infer State> ? State : unknown
> {
  @Input() definition!: TDef;

  protected _state!: TState;

  @Input() set state(val: TState) {
    this._state = this.parseState(val as any);
  }

  get state() {
    return this._state;
  }

  @Input() disabled = false;

  @Output() readonly stateChange = new EventEmitter<TState>();

  @Output() readonly expressionChange = new EventEmitter<object>();

  abstract createExpression(column: string, state: TState): object;

  abstract parseState(state: any): TState;

  modifyState?(state: TState): TState;

  updateStateAndExpression(state: TState) {
    const newState = this.modifyState ? this.modifyState(state) : state;
    this.stateChange.emit(newState);
    if (this.definition.createExpression) {
      this.expressionChange.emit(
        this.definition.createExpression(
          newState,
          this.createExpression.bind(this)
        )
      );
    } else {
      if (!this.definition.column) {
        throwColumnOrExpressionNotSpecified();
      } else {
        this.expressionChange.emit(
          this.createExpression(this.definition.column, newState)
        );
      }
    }
  }
}
