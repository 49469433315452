import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { ChangeDetectorRef, ElementRef, Component, ChangeDetectionStrategy, Optional, Self, Inject, ViewChildren, ViewChild, Input, NgModule } from '@angular/core';
import * as i5 from '@angular/forms';
import { NgControl, FormsModule } from '@angular/forms';
import * as i4 from '@taiga-ui/cdk';
import { AbstractTuiControl, EMPTY_QUERY, tuiIsNativeFocusedIn, tuiIsNativeFocused, tuiRound, tuiClamp, TUI_IS_MOBILE, tuiAsFocusableItemAccessor, tuiAsControl, tuiPure, TuiActiveZoneModule, TuiPressedModule } from '@taiga-ui/cdk';
import * as i3 from '@taiga-ui/core';
import { tuiGetFractionPartPadded, TUI_TEXTFIELD_WATCHED_CONTROLLER, TEXTFIELD_CONTROLLER_PROVIDER, TuiWrapperModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import * as i1 from '@taiga-ui/kit/components/input-number';
import { TuiInputNumberComponent, TuiInputNumberModule } from '@taiga-ui/kit/components/input-number';
import * as i2 from '@taiga-ui/kit/components/range';
import { TuiRangeComponent, TuiRangeModule } from '@taiga-ui/kit/components/range';
import { TUI_FLOATING_PRECISION } from '@taiga-ui/kit/constants';
import * as i6 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i7 from '@tinkoff/ng-polymorpheus';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
const _c0 = ["*"];
const _c1 = a0 => ({
  $implicit: a0
});
function TuiInputRangeComponent_div_4_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const text_r1 = ctx.polymorpheusOutlet;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", text_r1, " ");
  }
}
function TuiInputRangeComponent_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵtemplate(1, TuiInputRangeComponent_div_4_ng_container_1_Template, 2, 1, "ng-container", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r1.leftValueContent)("polymorpheusOutletContext", i0.ɵɵpureFunction1(2, _c1, ctx_r1.value[0]));
  }
}
function TuiInputRangeComponent_div_7_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const text_r3 = ctx.polymorpheusOutlet;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", text_r3, " ");
  }
}
function TuiInputRangeComponent_div_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵtemplate(1, TuiInputRangeComponent_div_7_ng_container_1_Template, 2, 1, "ng-container", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r1.rightValueContent)("polymorpheusOutletContext", i0.ɵɵpureFunction1(2, _c1, ctx_r1.value[1]));
  }
}
class TuiInputRangeComponent extends AbstractTuiControl {
  constructor(control, cdr, isMobile, el, controller) {
    super(control, cdr);
    this.isMobile = isMobile;
    this.el = el;
    this.controller = controller;
    this.inputNumberRefs = EMPTY_QUERY;
    this.rangeRef = null;
    this.min = 0;
    this.max = 100;
    this.quantum = 1;
    this.steps = 0;
    this.segments = 1;
    this.keySteps = null;
    this.pluralize = null;
    this.leftTextfieldValue = this.safeCurrentValue[0];
    this.rightTextfieldValue = this.safeCurrentValue[1];
    this.lastActiveSide = 'left';
  }
  get leftFocusableElement() {
    var _a;
    return ((_a = this.inputNumberRefs.first) === null || _a === void 0 ? void 0 : _a.nativeFocusableElement) || null;
  }
  get rightFocusableElement() {
    var _a;
    return ((_a = this.inputNumberRefs.last) === null || _a === void 0 ? void 0 : _a.nativeFocusableElement) || null;
  }
  get nativeFocusableElement() {
    return this.disabled ? null : this.leftFocusableElement || this.rightFocusableElement;
  }
  get focused() {
    return tuiIsNativeFocusedIn(this.el.nativeElement);
  }
  get appearance() {
    return this.controller.appearance;
  }
  get showLeftValueContent() {
    var _a;
    return Boolean(this.leftValueContent && !tuiIsNativeFocused(this.leftFocusableElement) && !(((_a = this.rangeRef) === null || _a === void 0 ? void 0 : _a.focused) && this.lastActiveSide === 'left'));
  }
  get showRightValueContent() {
    var _a;
    return Boolean(this.rightValueContent && !tuiIsNativeFocused(this.rightFocusableElement) && !(((_a = this.rangeRef) === null || _a === void 0 ? void 0 : _a.focused) && this.lastActiveSide === 'right'));
  }
  get precision() {
    return tuiGetFractionPartPadded(this.quantum).length;
  }
  get decimal() {
    return this.precision ? 'not-zero' : 'never';
  }
  get computedSteps() {
    return this.steps || (this.max - this.min) / this.quantum;
  }
  get step() {
    return (this.max - this.min) / this.computedSteps;
  }
  computeKeySteps(keySteps, min, max) {
    return keySteps || [[0, min], [100, max]];
  }
  onActiveZone(active) {
    this.updateFocused(active);
  }
  onTextInputFocused(focused) {
    if (!focused) {
      this.updateTextfieldValues(this.value);
    }
  }
  changeByStep(event, [leftCoefficient, rightCoefficient]) {
    if (this.readOnly) {
      return;
    }
    event.preventDefault();
    const newValue = this.valueGuard([this.value[0] + leftCoefficient * this.step, this.value[1] + rightCoefficient * this.step]);
    if (newValue[0] !== this.value[0] || newValue[1] !== this.value[1]) {
      this.onExternalValueUpdate(newValue);
    }
  }
  onInputLeft(value) {
    this.safelyUpdateValue([value !== null && value !== void 0 ? value : this.safeCurrentValue[0], this.value[1]]);
  }
  onInputRight(value) {
    this.safelyUpdateValue([this.value[0], value !== null && value !== void 0 ? value : this.safeCurrentValue[1]]);
  }
  onExternalValueUpdate(value) {
    this.safelyUpdateValue(value);
    this.updateTextfieldValues(this.value);
  }
  focusToTextInput() {
    const element = this.lastActiveSide === 'left' ? this.leftFocusableElement : this.rightFocusableElement;
    if (!this.isMobile && element) {
      element.focus();
    }
  }
  onActiveThumbChange(activeThumb) {
    this.lastActiveSide = activeThumb;
  }
  writeValue(value) {
    super.writeValue(value);
    this.updateTextfieldValues(this.value);
  }
  getFallbackValue() {
    return [0, 0];
  }
  safelyUpdateValue(value) {
    this.value = this.valueGuard(value);
  }
  valueGuard([leftValue, rightValue]) {
    const leftCalibratedValue = this.calibrate(leftValue);
    const rightCalibratedValue = this.calibrate(rightValue);
    return [Math.min(leftCalibratedValue, this.value[1]), Math.max(rightCalibratedValue, this.value[0])];
  }
  calibrate(value) {
    const roundedValue = tuiRound(Math.round(value / this.quantum) * this.quantum, TUI_FLOATING_PRECISION);
    return tuiClamp(roundedValue, this.min, this.max);
  }
  updateTextfieldValues([leftValue, rightValue]) {
    this.leftTextfieldValue = leftValue;
    this.rightTextfieldValue = rightValue;
  }
}
TuiInputRangeComponent.ɵfac = function TuiInputRangeComponent_Factory(t) {
  return new (t || TuiInputRangeComponent)(i0.ɵɵdirectiveInject(NgControl, 10), i0.ɵɵdirectiveInject(ChangeDetectorRef), i0.ɵɵdirectiveInject(TUI_IS_MOBILE), i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(TUI_TEXTFIELD_WATCHED_CONTROLLER));
};
TuiInputRangeComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiInputRangeComponent,
  selectors: [["tui-input-range"]],
  viewQuery: function TuiInputRangeComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(TuiRangeComponent, 5);
      i0.ɵɵviewQuery(TuiInputNumberComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.rangeRef = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.inputNumberRefs = _t);
    }
  },
  hostVars: 3,
  hostBindings: function TuiInputRangeComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("data-size", ctx.controller.size);
      i0.ɵɵclassProp("_label-outside", ctx.controller.labelOutside);
    }
  },
  inputs: {
    min: "min",
    max: "max",
    quantum: "quantum",
    steps: "steps",
    segments: "segments",
    keySteps: "keySteps",
    leftValueContent: "leftValueContent",
    rightValueContent: "rightValueContent",
    pluralize: "pluralize"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsFocusableItemAccessor(TuiInputRangeComponent), tuiAsControl(TuiInputRangeComponent), TEXTFIELD_CONTROLLER_PROVIDER]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c0,
  decls: 9,
  vars: 38,
  consts: [["tuiWrapper", "", 1, "t-wrapper", 3, "tuiActiveZoneChange", "appearance", "disabled", "focus", "hover", "invalid", "readOnly"], ["automation-id", "tui-input-range__left-input", "tuiTextfieldAppearance", "none", 1, "t-left", 3, "ngModelChange", "focusedChange", "keydown.arrowDown", "keydown.arrowUp", "decimal", "disabled", "max", "min", "precision", "readOnly", "tuiTextfieldPostfix", "ngModel"], ["ngProjectAs", "tuiContent", 5, ["tuiContent"], 4, "ngIf"], ["automation-id", "tui-input-range__right-input", "tuiTextfieldAppearance", "none", 1, "t-right", 3, "ngModelChange", "focusedChange", "keydown.arrowDown", "keydown.arrowUp", "decimal", "disabled", "max", "min", "precision", "readOnly", "tuiTextfieldPostfix", "ngModel"], [1, "t-range", 3, "activeThumbChange", "ngModelChange", "tuiPressedChange", "disabled", "focusable", "keySteps", "max", "min", "ngModel", "segments", "step"], ["ngProjectAs", "tuiContent", 5, ["tuiContent"]], [4, "polymorpheusOutlet", "polymorpheusOutletContext"]],
  template: function TuiInputRangeComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵlistener("tuiActiveZoneChange", function TuiInputRangeComponent_Template_div_tuiActiveZoneChange_0_listener($event) {
        return ctx.onActiveZone($event);
      });
      i0.ɵɵelementStart(1, "tui-input-number", 1);
      i0.ɵɵpipe(2, "i18nPlural");
      i0.ɵɵtwoWayListener("ngModelChange", function TuiInputRangeComponent_Template_tui_input_number_ngModelChange_1_listener($event) {
        i0.ɵɵtwoWayBindingSet(ctx.leftTextfieldValue, $event) || (ctx.leftTextfieldValue = $event);
        return $event;
      });
      i0.ɵɵlistener("focusedChange", function TuiInputRangeComponent_Template_tui_input_number_focusedChange_1_listener($event) {
        return ctx.onTextInputFocused($event);
      })("keydown.arrowDown", function TuiInputRangeComponent_Template_tui_input_number_keydown_arrowDown_1_listener($event) {
        return ctx.changeByStep($event, [-1, 0]);
      })("keydown.arrowUp", function TuiInputRangeComponent_Template_tui_input_number_keydown_arrowUp_1_listener($event) {
        return ctx.changeByStep($event, [1, 0]);
      })("ngModelChange", function TuiInputRangeComponent_Template_tui_input_number_ngModelChange_1_listener($event) {
        return ctx.onInputLeft($event);
      });
      i0.ɵɵprojection(3);
      i0.ɵɵtemplate(4, TuiInputRangeComponent_div_4_Template, 2, 4, "div", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(5, "tui-input-number", 3);
      i0.ɵɵpipe(6, "i18nPlural");
      i0.ɵɵtwoWayListener("ngModelChange", function TuiInputRangeComponent_Template_tui_input_number_ngModelChange_5_listener($event) {
        i0.ɵɵtwoWayBindingSet(ctx.rightTextfieldValue, $event) || (ctx.rightTextfieldValue = $event);
        return $event;
      });
      i0.ɵɵlistener("focusedChange", function TuiInputRangeComponent_Template_tui_input_number_focusedChange_5_listener($event) {
        return ctx.onTextInputFocused($event);
      })("keydown.arrowDown", function TuiInputRangeComponent_Template_tui_input_number_keydown_arrowDown_5_listener($event) {
        return ctx.changeByStep($event, [0, -1]);
      })("keydown.arrowUp", function TuiInputRangeComponent_Template_tui_input_number_keydown_arrowUp_5_listener($event) {
        return ctx.changeByStep($event, [0, 1]);
      })("ngModelChange", function TuiInputRangeComponent_Template_tui_input_number_ngModelChange_5_listener($event) {
        return ctx.onInputRight($event);
      });
      i0.ɵɵtemplate(7, TuiInputRangeComponent_div_7_Template, 2, 4, "div", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(8, "tui-range", 4);
      i0.ɵɵlistener("activeThumbChange", function TuiInputRangeComponent_Template_tui_range_activeThumbChange_8_listener($event) {
        return ctx.onActiveThumbChange($event);
      })("ngModelChange", function TuiInputRangeComponent_Template_tui_range_ngModelChange_8_listener($event) {
        return ctx.onExternalValueUpdate($event);
      })("tuiPressedChange", function TuiInputRangeComponent_Template_tui_range_tuiPressedChange_8_listener() {
        return ctx.focusToTextInput();
      });
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵproperty("appearance", ctx.appearance)("disabled", ctx.disabled)("focus", ctx.computedFocused)("hover", ctx.pseudoHover)("invalid", ctx.computedInvalid)("readOnly", ctx.readOnly);
      i0.ɵɵadvance();
      i0.ɵɵproperty("decimal", ctx.decimal)("disabled", ctx.computedDisabled)("max", ctx.value[1])("min", ctx.min)("precision", ctx.precision)("readOnly", ctx.readOnly)("tuiTextfieldPostfix", ctx.pluralize && !ctx.showLeftValueContent ? i0.ɵɵpipeBind2(2, 32, ctx.value[0], ctx.pluralize) : "");
      i0.ɵɵtwoWayProperty("ngModel", ctx.leftTextfieldValue);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngIf", ctx.showLeftValueContent);
      i0.ɵɵadvance();
      i0.ɵɵproperty("decimal", ctx.decimal)("disabled", ctx.computedDisabled)("max", ctx.max)("min", ctx.value[0])("precision", ctx.precision)("readOnly", ctx.readOnly)("tuiTextfieldPostfix", ctx.pluralize && !ctx.showRightValueContent ? i0.ɵɵpipeBind2(6, 35, ctx.value[1], ctx.pluralize) : "");
      i0.ɵɵtwoWayProperty("ngModel", ctx.rightTextfieldValue);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.showRightValueContent);
      i0.ɵɵadvance();
      i0.ɵɵproperty("disabled", ctx.readOnly || ctx.disabled)("focusable", false)("keySteps", ctx.computeKeySteps(ctx.keySteps, ctx.min, ctx.max))("max", ctx.max)("min", ctx.min)("ngModel", ctx.value)("segments", ctx.segments)("step", ctx.step);
    }
  },
  dependencies: [i1.TuiInputNumberComponent, i2.TuiRangeComponent, i3.TuiWrapperDirective, i4.TuiActiveZoneDirective, i1.TuiInputNumberDirective, i3.TuiTextfieldAppearanceDirective, i3.TuiTextfieldPostfixDirective, i5.NgControlStatus, i5.NgModel, i6.NgIf, i7.PolymorpheusOutletDirective, i2.TuiRangeChangeDirective, i4.TuiPressedDirective, i6.I18nPluralPipe],
  styles: [".t-wrapper[_ngcontent-%COMP%]{position:relative}.t-left[_ngcontent-%COMP%]{width:100%}.t-right[_ngcontent-%COMP%]{position:absolute;right:0;top:0;display:flex;width:50%;height:100%;text-align:right}[_nghost-%COMP%]{display:block;border-radius:var(--tui-radius-m)}.t-range[_ngcontent-%COMP%]{position:absolute;top:100%;left:0;right:0;z-index:1;margin:-.125rem 0 0;background:transparent}"],
  changeDetection: 0
});
__decorate([tuiPure], TuiInputRangeComponent.prototype, "computeKeySteps", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputRangeComponent, [{
    type: Component,
    args: [{
      selector: 'tui-input-range',
      templateUrl: './input-range.template.html',
      styleUrls: ['./input-range.style.less'],
      host: {
        '[attr.data-size]': 'controller.size',
        '[class._label-outside]': 'controller.labelOutside'
      },
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsFocusableItemAccessor(TuiInputRangeComponent), tuiAsControl(TuiInputRangeComponent), TEXTFIELD_CONTROLLER_PROVIDER]
    }]
  }], function () {
    return [{
      type: i5.NgControl,
      decorators: [{
        type: Optional
      }, {
        type: Self
      }, {
        type: Inject,
        args: [NgControl]
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_IS_MOBILE]
      }]
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: i3.TuiTextfieldController,
      decorators: [{
        type: Inject,
        args: [TUI_TEXTFIELD_WATCHED_CONTROLLER]
      }]
    }];
  }, {
    inputNumberRefs: [{
      type: ViewChildren,
      args: [TuiInputNumberComponent]
    }],
    rangeRef: [{
      type: ViewChild,
      args: [TuiRangeComponent]
    }],
    min: [{
      type: Input
    }],
    max: [{
      type: Input
    }],
    quantum: [{
      type: Input
    }],
    steps: [{
      type: Input
    }],
    segments: [{
      type: Input
    }],
    keySteps: [{
      type: Input
    }],
    leftValueContent: [{
      type: Input
    }],
    rightValueContent: [{
      type: Input
    }],
    pluralize: [{
      type: Input
    }],
    computeKeySteps: []
  });
})();
class TuiInputRangeModule {}
TuiInputRangeModule.ɵfac = function TuiInputRangeModule_Factory(t) {
  return new (t || TuiInputRangeModule)();
};
TuiInputRangeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiInputRangeModule
});
TuiInputRangeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, FormsModule, PolymorpheusModule, TuiActiveZoneModule, TuiInputNumberModule, TuiPressedModule, TuiRangeModule, TuiWrapperModule, TuiTextfieldControllerModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputRangeModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, FormsModule, PolymorpheusModule, TuiActiveZoneModule, TuiInputNumberModule, TuiPressedModule, TuiRangeModule, TuiWrapperModule, TuiTextfieldControllerModule],
      declarations: [TuiInputRangeComponent],
      exports: [TuiInputRangeComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiInputRangeComponent, TuiInputRangeModule };
