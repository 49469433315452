<ng-container *ngIf="definition as def">
  <ng-container *ngIf="def.type === 'checkbox'">
    <knk-simple-filter-checkbox-control
      [definition]="def"
      [disabled]="disabled"
      [state]="condition?.state"
      (stateChange)="updateState($event)"
      (expressionChange)="updateExpression($event)"
    ></knk-simple-filter-checkbox-control>
  </ng-container>

  <ng-container *ngIf="def.type === 'nullable-boolean'">
    <knk-simple-filter-nullable-boolean-control
      [definition]="def"
      [disabled]="disabled"
      [state]="condition?.state"
      (stateChange)="updateState($event)"
      (expressionChange)="updateExpression($event)"
    ></knk-simple-filter-nullable-boolean-control>
  </ng-container>

  <ng-container *ngIf="def.type === 'string'">
    <knk-simple-filter-string-control
      [definition]="def"
      [disabled]="disabled"
      [state]="condition?.state || null"
      (stateChange)="updateState($event)"
      (expressionChange)="updateExpression($event)"
    ></knk-simple-filter-string-control>
  </ng-container>

  <ng-container *ngIf="def.type === 'lookup'">
    <knk-simple-filter-lookup-control
      [definition]="def"
      [disabled]="disabled"
      [state]="condition?.state || null"
      (stateChange)="updateState($event)"
      (expressionChange)="updateExpression($event)"
    ></knk-simple-filter-lookup-control>
  </ng-container>

  <ng-container *ngIf="def.type === 'lookup-remote'">
    <knk-simple-filter-lookup-remote-control
      [definition]="def"
      [disabled]="disabled"
      [state]="condition?.state || null"
      (stateChange)="updateState($event)"
      (expressionChange)="updateExpression($event)"
    ></knk-simple-filter-lookup-remote-control>
  </ng-container>

  <ng-container *ngIf="def.type === 'date'">
    <knk-simple-filter-date-control
      [definition]="def"
      [disabled]="disabled"
      [state]="condition?.state || null"
      (stateChange)="updateState($event)"
      (expressionChange)="updateExpression($event)"
    ></knk-simple-filter-date-control>
  </ng-container>

  <ng-container *ngIf="def.type === 'birth-date'">
    <knk-simple-filter-birth-date-control
      [definition]="def"
      [disabled]="disabled"
      [state]="condition?.state || null"
      (stateChange)="updateState($event)"
      (expressionChange)="updateExpression($event)"
    ></knk-simple-filter-birth-date-control>
  </ng-container>

  <ng-container *ngIf="def.type === 'number-range'">
    <knk-simple-filter-number-range-control
      [definition]="def"
      [disabled]="disabled"
      [state]="condition?.state || null"
      (stateChange)="updateState($event)"
      (expressionChange)="updateExpression($event)"
    ></knk-simple-filter-number-range-control>
  </ng-container>

  <ng-container *ngIf="def.type === 'number'">
    <knk-simple-filter-number-control
      [definition]="def"
      [disabled]="disabled"
      [state]="condition?.state || null"
      (stateChange)="updateState($event)"
      (expressionChange)="updateExpression($event)"
    ></knk-simple-filter-number-control>
  </ng-container>

  <ng-container *ngIf="def.type === 'communication-response'">
    <knk-simple-filter-communication-response-control
      [definition]="def"
      [disabled]="disabled"
      [state]="condition?.state || null"
      (stateChange)="updateState($event)"
      (expressionChange)="updateExpression($event)"
    ></knk-simple-filter-communication-response-control>
  </ng-container>

  <ng-container *ngIf="isCustomControl(def)">
    <ndc-dynamic
      [ndcDynamicComponent]="def.type"
      [ndcDynamicInputs]="{
        definition: def,
        disabled: disabled,
        state: condition?.state || null
      }"
      [ndcDynamicOutputs]="{
        stateChange: updateState.bind(this),
        expressionChange: updateExpression.bind(this)
      }"
    >
    </ndc-dynamic>
  </ng-container>
</ng-container>

<button
  tuiIconButton
  appearance="icon"
  icon="tuiIconTrash"
  (click)="removeSelf()"
  *ngIf="canRemove && !disabled"
></button>
