<div tuiGroup>
  <tui-hosted-dropdown [(open)]="open" [content]="addConditionDropdown" class="tui-group__auto-width-item">
    <button class="tui-group__inherit-item" size="m" appearance="secondary" tuiButton type="button">
      + {{ 'segmentEditor.simpleFilter.addConditionLabel' | translate }}
    </button>
  </tui-hosted-dropdown>
  <tui-hosted-dropdown tuiDropdownAlign="left" [content]="addGroupDropdown" class="tui-group__auto-width-item">
    <button class="tui-group__inherit-item" appearance="secondary" size="m" tuiIconButton type="button" [iconRight]="icon"></button>
    <ng-template #icon>
      <tui-svg src="tuiIconChevronDown" class="icon"></tui-svg>
    </ng-template>
  </tui-hosted-dropdown>
</div>

<ng-template #addGroupDropdown>
  <tui-data-list>
    <button tuiOption (click)="apply(LogicalOperator.or)">
      + {{ 'segmentEditor.simpleFilter.applyConditionsInGroupLabel' | translate }}
    </button>
  </tui-data-list>
</ng-template>

<ng-template #addConditionDropdown let-activeZone>
  <div class="dropdown-header">
    <tui-primitive-textfield
      #input
      tuiTextfieldIconLeft="tuiIconSearchLarge"
      [tuiTextfieldLabelOutside]="true"
      [(value)]="search"
      tuiTextfieldSize="m"
    >
      {{ 'segmentEditor.simpleFilter.searchConditionInputLabel' | translate }}
    </tui-primitive-textfield>
  </div>
  <tui-data-list style="min-width: 400px">
    <ng-container *ngFor="let group of conditionBlocks">
      <ng-container *tuiLet="group.conditions | tuiFilter: filter : search as filtered">
        <tui-opt-group *ngIf="filtered.length" tuiMultiSelectGroup [(ngModel)]="selectedConditions">
          <tui-opt-group [label]="group.label | translate">
            <button tuiOption [value]="conditionDef" *ngFor="let conditionDef of filtered">
              {{ conditionDef.label | translate }}
            </button>
          </tui-opt-group>
        </tui-opt-group>
      </ng-container>
    </ng-container>
  </tui-data-list>
  <div class="dropdown-footer">
    <button (click)="apply()" [disabled]="!selectedConditions.length" style="margin-left: auto" size="m" tuiButton>
      {{ 'segmentEditor.simpleFilter.applyConditionsLabel' | translate }}
    </button>
  </div>
</ng-template>
