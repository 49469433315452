import { WidgetGroupConfig } from '@konnektu/widgets';
import { SegmentInfoWidgetComponent } from './widgets/info/info.widget';
import { SegmentVersionsWidgetComponent } from './widgets/versions/versions.widget';

export const segmentCardWidgetGroup: WidgetGroupConfig = {
  groupId: 'segment',
  widgets: [
    {
      componentTypeCtor: SegmentInfoWidgetComponent,
      componentTypeName: 'SegmentInfoWidgetComponent',
      label: 'Information',
      minCols: 4,
      minRows: 3,
      cols: 4,
      rows: 3,
      x: 0,
      y: 0
    },
    {
      componentTypeCtor: SegmentVersionsWidgetComponent,
      componentTypeName: 'SegmentVersionsWidgetComponent',
      label: 'Versions',
      minCols: 4,
      minRows: 3,
      cols: 4,
      rows: 5,
      x: 4,
      y: 0
    }
  ]
};
