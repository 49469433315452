<div class="knk-segment-versions" *ngIf="versions$ | async as versions">
  <div class="knk-segment-versions__header">
    <div class="knk-segment-versions__header__main">
      <h2 class="big-text">{{ 'segmentsVersion'| translate }}</h2>

      <knk-button
        preset="secondary"
        class="knk-segment-versions__header__main__to-segments-button"
        (clicked)="redirectToVersionsSection()"
      >
        {{'segmentsVersionWatchAll' | translate }}
      </knk-button>
    </div>

    <span class="additional-text"> {{'segmentsVersionDescription' | translate}} </span>
  </div>

  <div class="knk-segment-versions__list">
    <div class="knk-segment-versions__list-item" *ngFor="let version of versions">
      <div>№ {{ version.version }}</div>

      <div class="knk-segment-versions__list-item__text">
        <div>{{ version.timestamp | date:'dd.MM.yyyy HH:mm' }}</div>

        <div class="knk-segment-versions__list-item__text-description" *ngIf="version.snapshot.count !== undefined">
          <!-- <knk-icon key="customer_card" size="md"></knk-icon> -->
          {{ 'versionTotalDescription' | translate:{total: version.snapshot.count} }}
        </div>
      </div>

      <div class="knk-segment-versions__list-item__count">
        <div *ngIf="version.added" class="knk-segment-versions__list-item__count__up">
          <knk-icon key="expand_less"></knk-icon>
          {{ version.added.count }} {{ 'versionAddedCount' | translate }}
        </div>

        <div *ngIf="version.removed" class="knk-segment-versions__list-item__count__down">
          <knk-icon key="expand_more"></knk-icon>
          {{ version.removed.count }} {{ 'versionRemovedCount' | translate }}
        </div>
      </div>

      <div
        class="knk-segment-versions__list-item__download"
        *ngIf="version.snapshot?.uri && (canLoadVersionSnapshot$ | async)"
      >
        <a [href]="version.snapshot.uri">
          <knk-icon key="download"></knk-icon>
        </a>
      </div>
    </div>
  </div>
</div>
