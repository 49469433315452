<span class="tui-text_h5 tui-space_bottom-2">
  {{ context.data ? ('segmentEditor.editHeading' | translate) : ('segmentEditor.createHeading' | translate) }}
</span>

<tui-input [formControl]="form.controls.name">
  {{ 'segmentEditor.nameInputLabel' | translate }}
  <span class="tui-required"></span>
</tui-input>

@if (!canEditFieldsAndExpression) {
  <tui-notification class="tui-space_top-2" style="align-items: center" size="l" status="warning">
    <span>{{ 'segmentEditor.editDisabledDescription' | translate }}</span>
  </tui-notification>
}

<div class="tui-space_top-2" tuiGroup [collapsed]="true">
  <tui-radio-block
    [item]="0"
    [ngModel]="activeTabIndex"
    (click)="changeMode(0)"
    [disabled]="!canSwitchModes || !canEditFieldsAndExpression"
    size="l"
  >
    {{ 'segmentEditor.simplifiedFilterTabLabel' | translate }}
  </tui-radio-block>
  <tui-radio-block
    [item]="1"
    [ngModel]="activeTabIndex"
    (click)="changeMode(1)"
    [disabled]="!canSwitchModes || !canEditFieldsAndExpression"
    size="l"
  >
    {{ 'segmentEditor.defaultFilterTabLabel' | translate }}
  </tui-radio-block>
</div>

<div class="tui-space_top-2 tui-space_bottom-2 tabs-content">
  @if (activeTabIndex === 0) {
    <knk-segment-simple-filter
      class="h-full"
      (expressionChange)="updateExpression($event)"
      [state]="simpleFilterState"
      (stateChange)="onStateChange($event)"
      [disabled]="!canEditFieldsAndExpression"
      [template]="selectedSimpleFilterTemplate"
      (templateChange)="updateFromTemplate($event)"
    ></knk-segment-simple-filter>
  }

  @if (activeTabIndex === 1) {
    <tui-select
      class="tui-space_bottom-2"
      [disabled]="!isCreatingSegment"
      [valueContent]="stringifySegmentType"
      [ngModel]="selectedSegmentType()"
      (ngModelChange)="selectSegmentType($event)"
    >
      {{ 'segmentEditor.segmentTypeSelectLabel' | translate }}
      <tui-data-list *tuiDataList>
        <button tuiOption [value]="SEGMENT_EMPTY_TYPE">
          {{ 'segmentType.noType' | translate }}
        </button>
        <button tuiOption [value]="SegmentType.User">
          {{ 'segmentType.user' | translate }}
        </button>
      </tui-data-list>
    </tui-select>

    <tui-select
      *tuiLet="entityNameItems$ | async as items"
      [ngModel]="form.controls.entityName.value"
      (ngModelChange)="form.controls.entityName.setValue($event)"
      [disabled]="!canEditFieldsAndExpression || isSegmentTypeSelected()"
      [valueContent]="items ? stringify(items) : loading"
      [tuiTextfieldCleaner]="true"
    >
      {{ 'segmentEditor.entityNameSelectLabel' | translate }}
      <span class="tui-required"></span>
      <ng-container *tuiDataList>
        <tui-input
          tuiTextfieldIconLeft="tuiIconSearchLarge"
          class="tui-space_all-2"
          #input
          [disabled]="!canEditFieldsAndExpression"
          (keydown.arrowDown.prevent)="onArrowDown(dataList, $event)"
          [tuiTextfieldLabelOutside]="true"
          tuiTextfieldSize="m"
          [(ngModel)]="searchEntityName"
        >
          {{ 'segmentEditor.searchEntityNameInputLabel' | translate }}
        </tui-input>

        <tui-data-list
          #dataList
          [emptyContent]="'segmentEditor.entityNameSelectEmpty' | translate"
          (keydown)="onKeyDown($event.key, input.nativeFocusableElement)"
        >
          @if (entityNameItems$ | async; as entityNameItems) {
            @for (item of entityNameItems | tuiFilter: filterEntityName : searchEntityName; track $index) {
              <button tuiOption [value]="item.value">
                {{ item.caption | translate }}
              </button>
            }
          } @else {
            <tui-loader></tui-loader>
          }
        </tui-data-list>
      </ng-container>

      <ng-template #loading>
        <tui-loader></tui-loader>
      </ng-template>
    </tui-select>

    @if (selectedEntityMetadata(); as tableMeta) {
      <knk-segment-field-selector
        class="tui-space_top-2"
        [tableMetadata]="tableMeta"
        [disabled]="!canEditFieldsAndExpression"
        [tables]="(metadataProvider$ | async) || []"
        (columnsChanged)="updateColumns($event)"
        [fields]="fieldsForFieldSelector"
        [isIndexUnique]="form.controls.isIndexUnique.value"
        [isIndexUniqueVisible]="!isSegmentTypeSelected()"
        [isColumnIndexVisible]="!isSegmentTypeSelected()"
      ></knk-segment-field-selector>
    }

    @if (form.controls.entityName.value; as entityName) {
      <tui-scrollbar class="tui-space_top-2">
        <knk-expression-builder
          class="tui-space_top-2"
          [entityName]="entityName"
          [metadataProvider]="metadataProvider$"
          [expression]="form.controls.expression.value"
          [showApply]="false"
          (expressionChanged)="updateExpression($event)"
          (removed)="updateExpression(null)"
        ></knk-expression-builder>
      </tui-scrollbar>
    }
  }
</div>

<div class="bottom-controls">
  <button appearance="secondary" class="cancel" tuiButton (click)="onCancel()">
    {{ 'common.cancel' | translate }}
  </button>
  <button class="done" [disabled]="form.invalid" tuiButton (click)="onDone()">
    {{ 'segmentEditor.saveButtonLabel' | translate: { creating: isCreatingSegment } }}
  </button>
</div>
