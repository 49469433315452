<knk-job-scheduler
  section="segments"
  [showDoNotRecount]="false"
  [onlyPlain]="!!scheduleId"
  [cron]="cron"
  (cronChanged)="cron = $event"
></knk-job-scheduler>

<button tuiButton (click)="done()">{{ 'common.done' | translate }}</button>

<button
  tuiButton
  appearance="secondary-destructive"
  class="delete-button"
  *ngIf="scheduleId"
  (click)="removeJob()"
>
  {{ 'Remove job' | translate }}
</button>
