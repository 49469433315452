import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { ElementRef, Directive, Self, Inject, ContentChildren, NgModule } from '@angular/core';
import * as i1 from '@taiga-ui/cdk';
import { EMPTY_QUERY, tuiTypedFromEvent, tuiQueryListChanges, tuiPreventDefault, tuiGetClosestFocusable, TuiDestroyService, tuiPure } from '@taiga-ui/cdk';
import { TuiDropdownDirective } from '@taiga-ui/core';
import { merge, EMPTY } from 'rxjs';
import { takeUntil, switchMap, take, filter, tap, map, shareReplay, debounceTime } from 'rxjs/operators';
class TuiDataListDropdownManagerDirective {
  constructor(destroy$) {
    this.destroy$ = destroy$;
    this.dropdowns = EMPTY_QUERY;
    this.els = EMPTY_QUERY;
  }
  ngAfterViewInit() {
    this.right$.pipe(takeUntil(this.destroy$)).subscribe(index => {
      this.tryToFocus(index);
    });
    merge(this.immediate$, this.debounce$).pipe(switchMap(active => {
      this.dropdowns.forEach((dropdown, index) => {
        dropdown.toggle(index === active);
      });
      const element = this.els.get(active);
      const dropdown = this.dropdowns.get(active);
      if (!element || !(dropdown === null || dropdown === void 0 ? void 0 : dropdown.dropdownBoxRef)) {
        return EMPTY;
      }
      const {
        nativeElement
      } = dropdown.dropdownBoxRef.location;
      const mouseEnter$ = tuiTypedFromEvent(nativeElement, 'mouseenter').pipe(take(1));
      const esc$ = merge(tuiTypedFromEvent(element.nativeElement, 'keydown'), tuiTypedFromEvent(nativeElement, 'keydown')).pipe(filter(({
        key
      }) => key === 'Escape'));
      return merge(mouseEnter$, esc$).pipe(tap(event => {
        if (dropdown.dropdownBoxRef) {
          event.stopPropagation();
        }
        element.nativeElement.focus();
        dropdown.toggle('offsetX' in event);
      }));
    }), takeUntil(this.destroy$)).subscribe();
  }
  get elements$() {
    return tuiQueryListChanges(this.els).pipe(map(array => array.map(({
      nativeElement
    }) => nativeElement)), shareReplay({
      bufferSize: 1,
      refCount: true
    }));
  }
  get right$() {
    return this.elements$.pipe(switchMap(elements => merge(...elements.map((element, index) => tuiTypedFromEvent(element, 'keydown').pipe(filter(({
      key
    }) => key === 'ArrowRight'), tuiPreventDefault(), map(() => index))))));
  }
  get immediate$() {
    return this.elements$.pipe(switchMap(elements => merge(...elements.map((element, index) => tuiTypedFromEvent(element, 'click').pipe(map(() => index))))));
  }
  get debounce$() {
    return this.elements$.pipe(switchMap(elements => merge(...elements.map((element, index) => merge(tuiTypedFromEvent(element, 'focus'), tuiTypedFromEvent(element, 'blur')).pipe(filter(({
      relatedTarget
    }) => this.notInDropdown(relatedTarget, index)), map(({
      type
    }) => type === 'focus' ? index : NaN))))), debounceTime(300));
  }
  notInDropdown(element, index) {
    var _a, _b;
    return !((_b = (_a = this.dropdowns.get(index)) === null || _a === void 0 ? void 0 : _a.dropdownBoxRef) === null || _b === void 0 ? void 0 : _b.location.nativeElement.contains(element));
  }
  tryToFocus(index) {
    var _a, _b;
    const content = (_b = (_a = this.dropdowns.get(index)) === null || _a === void 0 ? void 0 : _a.dropdownBoxRef) === null || _b === void 0 ? void 0 : _b.location.nativeElement;
    if (!content) {
      return;
    }
    // First item is focus trap
    const focusTrap = tuiGetClosestFocusable({
      initial: content,
      root: content
    });
    const item = tuiGetClosestFocusable({
      initial: focusTrap || content,
      root: content
    });
    if (item) {
      item.focus();
    }
  }
}
TuiDataListDropdownManagerDirective.ɵfac = function TuiDataListDropdownManagerDirective_Factory(t) {
  return new (t || TuiDataListDropdownManagerDirective)(i0.ɵɵdirectiveInject(TuiDestroyService, 2));
};
TuiDataListDropdownManagerDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiDataListDropdownManagerDirective,
  selectors: [["tui-data-list", "tuiDataListDropdownManager", ""]],
  contentQueries: function TuiDataListDropdownManagerDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, TuiDropdownDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, TuiDropdownDirective, 5, ElementRef);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.dropdowns = _t);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.els = _t);
    }
  },
  features: [i0.ɵɵProvidersFeature([TuiDestroyService])]
});
__decorate([tuiPure], TuiDataListDropdownManagerDirective.prototype, "elements$", null);
__decorate([tuiPure], TuiDataListDropdownManagerDirective.prototype, "right$", null);
__decorate([tuiPure], TuiDataListDropdownManagerDirective.prototype, "immediate$", null);
__decorate([tuiPure], TuiDataListDropdownManagerDirective.prototype, "debounce$", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDataListDropdownManagerDirective, [{
    type: Directive,
    args: [{
      selector: 'tui-data-list[tuiDataListDropdownManager]',
      providers: [TuiDestroyService]
    }]
  }], function () {
    return [{
      type: i1.TuiDestroyService,
      decorators: [{
        type: Self
      }, {
        type: Inject,
        args: [TuiDestroyService]
      }]
    }];
  }, {
    dropdowns: [{
      type: ContentChildren,
      args: [TuiDropdownDirective, {
        descendants: true
      }]
    }],
    els: [{
      type: ContentChildren,
      args: [TuiDropdownDirective, {
        read: ElementRef,
        descendants: true
      }]
    }],
    elements$: [],
    right$: [],
    immediate$: [],
    debounce$: []
  });
})();
class TuiDataListDropdownManagerModule {}
TuiDataListDropdownManagerModule.ɵfac = function TuiDataListDropdownManagerModule_Factory(t) {
  return new (t || TuiDataListDropdownManagerModule)();
};
TuiDataListDropdownManagerModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiDataListDropdownManagerModule
});
TuiDataListDropdownManagerModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDataListDropdownManagerModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiDataListDropdownManagerDirective],
      exports: [TuiDataListDropdownManagerDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiDataListDropdownManagerDirective, TuiDataListDropdownManagerModule };
