import * as i0 from '@angular/core';
import { Pipe, Inject, NgModule } from '@angular/core';
import * as i1 from '@taiga-ui/core/services';
import { TuiFormatDateService } from '@taiga-ui/core/services';
class TuiFormatDatePipe {
  constructor(service) {
    this.service = service;
  }
  transform(timestampOrDate) {
    return this.service.format(timestampOrDate.valueOf());
  }
}
TuiFormatDatePipe.ɵfac = function TuiFormatDatePipe_Factory(t) {
  return new (t || TuiFormatDatePipe)(i0.ɵɵdirectiveInject(TuiFormatDateService, 16));
};
TuiFormatDatePipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "tuiFormatDate",
  type: TuiFormatDatePipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFormatDatePipe, [{
    type: Pipe,
    args: [{
      name: 'tuiFormatDate'
    }]
  }], function () {
    return [{
      type: i1.TuiFormatDateService,
      decorators: [{
        type: Inject,
        args: [TuiFormatDateService]
      }]
    }];
  }, null);
})();
class TuiFormatDatePipeModule {}
TuiFormatDatePipeModule.ɵfac = function TuiFormatDatePipeModule_Factory(t) {
  return new (t || TuiFormatDatePipeModule)();
};
TuiFormatDatePipeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiFormatDatePipeModule
});
TuiFormatDatePipeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFormatDatePipeModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiFormatDatePipe],
      exports: [TuiFormatDatePipe]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiFormatDatePipe, TuiFormatDatePipeModule };
