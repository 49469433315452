import { Injectable, inject } from '@angular/core';
import { isArray, uniqBy } from 'lodash-es';
import { ConditionDef, SimpleFilterTemplate } from './models';
import { SIMPLE_FILTER_TEMPLATES } from './tokens';

@Injectable({ providedIn: 'root' })
export class SimpleFilterTemplateStore {
  private readonly templatesFromDi = inject(SIMPLE_FILTER_TEMPLATES, {
    optional: true
  });

  private _templates: SimpleFilterTemplate[] = this.templatesFromDi ?? [];

  get blocks() {
    return this._templates.flatMap((t) => t.conditionBlocks);
  }

  get conditionDefinitions() {
    return uniqBy(
      this.blocks.flatMap((b) =>
        b.conditions.flatMap((c) => (isArray(c) ? c[1] : c))
      ),
      (b) => b.id
    ) as ConditionDef[];
  }

  set templates(val: SimpleFilterTemplate[]) {
    this._templates = val;
  }

  get templates() {
    return this._templates;
  }

  addTemplates(...template: SimpleFilterTemplate[]) {
    this.templates.push(...template);
  }
}
