<div class="simple-filter">
  <tui-select
    [ngModel]="template"
    [disabled]="disabled"
    (ngModelChange)="selectTemplate($event)"
    [valueContent]="template?.label ?? '' | translate"
  >
    {{ 'segmentEditor.simpleFilter.templateSelectLabel' | translate }}
    <ng-container *tuiDataList>
      <tui-data-list [emptyContent]="'segmentEditor.simpleFilter.templateSelectEmpty' | translate">
        <button *ngFor="let template of templates" tuiOption [value]="template">
          {{ template.label | translate }}
        </button>
      </tui-data-list>
    </ng-container>
  </tui-select>

  <ng-container *ngIf="template as template">
    <div class="template-body">
      <tui-scrollbar *ngIf="simpleFilterState as filterState; else onlyButton">
        <ng-container *ngIf="isGroup(filterState)">
          <knk-simple-filter-condition-group
            [group]="filterState"
            (groupChange)="updateState($event)"
            [disabled]="disabled"
            [conditionBlocks]="conditionBlocks"
            (remove)="resetFilter()"
          ></knk-simple-filter-condition-group>
        </ng-container>

        <ng-container *ngIf="isCondition(filterState)">
          <div class="single-condition">
            <knk-simple-filter-condition-control
              [condition]="filterState"
              (conditionChange)="updateState($event)"
              [canRemove]="true"
              [definition]="getConditionDef(filterState.definitionId)!"
              (remove)="resetFilter()"
              [disabled]="disabled"
            ></knk-simple-filter-condition-control>
            <knk-simple-filter-condition-dropdown
              [canGroupOptions]="true"
              *ngIf="!disabled"
              [conditionBlocks]="conditionBlocks"
              (applyConditions)="addConditions($event.items, $event.groupWith)"
            ></knk-simple-filter-condition-dropdown>
          </div>
        </ng-container>
      </tui-scrollbar>

      <ng-template #onlyButton>
        <knk-simple-filter-condition-dropdown
          [canGroupOptions]="false"
          *ngIf="!disabled"
          [conditionBlocks]="conditionBlocks"
          (applyConditions)="addConditions($event.items, $event.groupWith)"
        ></knk-simple-filter-condition-dropdown>
      </ng-template>
    </div>
  </ng-container>
</div>
